import { useFormikContext } from "formik";
import * as React from "react";
import { ContactCreatorFormModel, ModalType } from "../../../common/typings";
import { Button, Modal } from "@sgbs-ui/core";
import {  ContactDbDto } from "../../../api/contacts/contacts.typings";
import { useEditContactPositionModal } from "./hooks/useEditContactPositionModal";
import { useEditContactPositionActions } from "./hooks/useEditContactPositionActions";
import UpdateContactPosition from "./UpdateContactPosition/UpdateContactPosition";

export interface UpdatePosition {
  contactIds: string[];
  contact: ContactCreatorFormModel;
}

interface Props {
  contact: ContactDbDto;
}

const EditContactChangePositionModal: React.FC<Props> = ({ contact }: Props) => {
  const [payload, setPayload] = React.useState<UpdatePosition>();

  const { values } = useFormikContext<ContactCreatorFormModel>();

  const [ref, step, setStep, title, subTitle] = useEditContactPositionModal();

  const [isLoading, modalType, editContact, confirmUpdate, closeModal, sendLog] = useEditContactPositionActions(
    values,
    payload?.contactIds,
    contact?.id,
    contact?.person?.id
  );

  const handleOnClose = (): void => {
    setStep("step1");
    closeModal();
    sendLog("close", "click on close modal or cancel");
  };


  const handleOnUpdate = (): void => {
    setStep("step2");
  }

  const handleOnConfirmUpdate = (): void => {
    confirmUpdate();
  };

  const handleOnSelectChange = (contactIds: string[]) => {
    setPayload({ contactIds, contact: values });
  };

  const handleOnBack = () => {
    setStep("step1");
    sendLog("back", "click on back");
  };

  const renderActions = (): JSX.Element => {
    return step === "step1" ? (
      <>
        <Button
          color="flat-secondary"
          className="mr-1"
          text="No, continue to edit"
          disabled={isLoading}
          onClick={editContact}
        />
        <Button
          color="primary"
          text={"Yes, create a new work position"}
          disabled={isLoading}
          onClick={handleOnUpdate}
        />
      </>
    ) : (
      <div className="d-flex justify-content-between flex-grow-1">
        <div>
          <Button btnType="default" text="Cancel" disabled={isLoading} onClick={handleOnClose} />
        </div>
        <div>
          <Button
            color="flat-secondary"
            className="mr-1"
            text="Back"
            disabled={isLoading}
            onClick={() => setStep("step1")}
          />

          <Button color="primary" text={"Confirm update"} disabled={isLoading} onClick={handleOnConfirmUpdate} />
        </div>
      </div>
    );
  };

  const renderHeader = (): JSX.Element => {
    return (
      <div className="d-flex justify-content-between flex-grow-1">
        <div className="d-flex flex-grow-1 align-items-center">
          {step === "step2" && (
            <div className="mr-2">
              <Button color="flat-secondary" icon="arrow_back" onClick={handleOnBack} />
            </div>
          )}

          <h4 className="modal-title text-black"> {title} </h4>
        </div>

        <div>
          <Button color="flat-secondary" icon="close" onClick={handleOnClose} />
        </div>
      </div>
    );
  };

  return modalType === ModalType.EditContactPositionChange ? (
    <div ref={ref}>
      <Modal
        show={modalType === ModalType.EditContactPositionChange}
        size="lg"
        isScrollable={true}
        renderFooterActions={renderActions}
        footerClassName="modal-footer"
        onClose={handleOnClose}
        renderHeaderActions={renderHeader}
      >
        <div className="text-medium font-weight-500"> {subTitle} </div>

        {step === "step2" && (
          <UpdateContactPosition
            contact={contact}
            onSelectionChange={handleOnSelectChange}
          />
        )}
      </Modal>
    </div>
  ) : null;
};

export default EditContactChangePositionModal;
