import React from "react";
import { Radio, Checkbox } from "@sgbs-ui/core";
import { ContactDbDto, ContactPosition } from "../../../../../api/contacts/contacts.typings";
import { size } from "lodash-es";

type CloseAndUpdatePositionCardProps = {
  contact: ContactDbDto;
  positions: ContactPosition[];
  onSelect?: (contactIds: string[]) => void;
  active?: boolean;
};

const CloseAndUpdatePositionCard: React.FC<CloseAndUpdatePositionCardProps> = ({
  contact,
  positions,
  onSelect,
  active,
}) => {
  const [selectedContactIds, setSelectedContacts] = React.useState<string[]>(
    size(positions) === 1 ? [positions[0]?.id] : []
  );

  React.useEffect(() => {
    const firstElement = positions.find(pos => pos.id === contact.id);
    if (!!firstElement && !selectedContactIds.find(id => id === firstElement.id)) {
      setSelectedContacts([firstElement.id, ...selectedContactIds]);
    }
  }, [positions, contact]);

  React.useEffect(() => {
    onSelect?.(selectedContactIds);
  }, [selectedContactIds]);

  const handleOnChange = (id: string) => {
    if (!!selectedContactIds.find(contactId => id === contactId)) {
      setSelectedContacts([...selectedContactIds].filter(contactId => contactId !== id));
    } else {
      setSelectedContacts([...selectedContactIds, id]);
    }
  };

  return (
    <div className={`card card-bordered p-3 my-3 ${active ? "active" : ""}`}>
      <div className="d-flex flex-row flex-grow-1">
        {onSelect && (
          <div className="col-1">
            <Radio
              id="radio-update-position"
              color="primary"
              checked={active}
              onClick={() => onSelect?.(selectedContactIds)}
            />
          </div>
        )}

        <div className="flex-grow-1">
          <div className="font-weight-semibold"> Add and close one or more current positions </div>
          <div>This action will close the current position and replace it with the new one</div>
          <div className="text-small font-weight-semibold mt-2">Select the position to close: </div>
          {positions?.map(position => (
            <Checkbox
              key={position.id}
              id={`checkbox-position-${position.id}`}
              disabled={positions?.length === 1}
              checked={!!selectedContactIds.find(contactId => position.id === contactId)}
              onChange={() => handleOnChange(position.id)}
              text={`${position.jobType} at ${position.account?.name} (${position.account?.clientIds?.internalId?.level}) ${position.account?.country?.iso3}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CloseAndUpdatePositionCard;
