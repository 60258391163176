import actionCreatorFactory, { AnyAction, isType } from "typescript-fsa";
import { ContactSuggestion, Error, ContactPosition } from "../../api/contacts/contacts.typings";
import { Address, Country, IdNames, ModalType, ProgressStep } from "../../common/typings";
import { PersonContact, SavedPersonContact } from "../../common/typings/contacts.typings";

const actionCreator = actionCreatorFactory("contactCreator");

export const getContactSuggestionAction = actionCreator.async<string, ContactSuggestion, string>(
  "GET_CONTACT_SUGGESTION"
);
export const getPersonByIdAction = actionCreator.async<string, PersonContact | null, string>("GET_PERSON_BY_ID");
export const createContactAction = actionCreator.async<void, SavedPersonContact, Error | null>("CREATE_CONTACT");
export const editContactAction = actionCreator.async<void, SavedPersonContact, Error | null>("EDIT_CONTACT");
//TODO: put the right type not {}
export const checkEmailDetailsAction = actionCreator.async<any, any, Error | null>("CHECK_EMAIL_DETIALS");

export const getPersonalEmailJustificationsAction = actionCreator.async<
  any,
  { justifications: IdNames<number>; unjustifiedPersonalEmails?: Array<{ email: string }> },
  string
>("GET_PERSONAL_EMAIL_JUSTIFICATIONS");
export const getCountriesAction = actionCreator.async<any, Country[], string>("GET_COUNTRIES");
export const setModalTypeAction = actionCreator<ModalType | null>("SET_MODAL_TYPE");
export const setEtagAction = actionCreator<string | undefined>("SET_ETAG");
export const setForceSaveWithEtagAction = actionCreator<boolean>("SET_FORCE_SAVE_WITH_ETAG");
export const setProgressStepAction = actionCreator<ProgressStep>("SET_PROGRESS_STEP");
export const setMainAddressAction = actionCreator<Address>("SET_MAIN_ADDRESS");

export const checkForSimilarAction = actionCreator.async<void, ContactPosition[], Error | null>("CHECK_SIMILAR");
export const endPositionsAction = actionCreator.async<void, void, Error | null>("END_POSITIONS");
export const resetContextAction = actionCreator.async<void, void, Error | null>("RESET_CONTEXT");

export interface ContactCreatorState {
  justifications: IdNames<number>;
  unjustifiedPersonalEmails: Array<{ email: string }>;
  countries: Country[];
  modalType: ModalType | null;
  currentStep: ProgressStep;
  emailCheckError: Error | null;
  emailCheckSuccess: boolean | null;
  contact: SavedPersonContact | null;
  contactSuggestion: ContactSuggestion | null;
  person: PersonContact | null;
  isLoading: boolean;
  saveError: Error | null;
  etag?: string;
  forceSaveWithEtag: boolean;
  mainAddress: Address | null;
  similarContacts: ContactPosition[];
  endPositionsError: Error | null;
  endPositionLoading: boolean;
  isChangePosition: boolean;
}

export const initialContactCreatorState: ContactCreatorState = {
  justifications: [],
  unjustifiedPersonalEmails: [],
  countries: [],
  modalType: null,
  currentStep: ProgressStep.PersonalInformation,
  emailCheckError: null,
  emailCheckSuccess: false,
  contact: null,
  contactSuggestion: null,
  person: null,
  saveError: null,
  isLoading: false,
  etag: undefined,
  forceSaveWithEtag: false,
  mainAddress: null,
  similarContacts: [],
  endPositionsError: null,
  endPositionLoading: false,
  isChangePosition: false,
};

export const contactCreatorReducer = (
  state: ContactCreatorState = initialContactCreatorState,
  action: AnyAction
): ContactCreatorState => {
  if (isType(action, getContactSuggestionAction.done)) {
    return { ...state, contactSuggestion: action.payload.result };
  }
  if (isType(action, getContactSuggestionAction.failed)) {
    return { ...state, contactSuggestion: null };
  }

  if (isType(action, createContactAction.done)) {
    return { ...state, contact: action.payload.result, isLoading: false, saveError: null };
  }

  if (isType(action, createContactAction.started)) {
    return { ...state, isLoading: true, saveError: null };
  }

  if (isType(action, createContactAction.failed)) {
    return { ...state, isLoading: false, saveError: action.payload.error };
  }

  if (isType(action, editContactAction.started)) {
    return { ...state, isLoading: true, saveError: null };
  }

  if (isType(action, editContactAction.done)) {
    return { ...state, contact: action.payload.result, isLoading: false, saveError: null };
  }

  if (isType(action, editContactAction.failed)) {
    return { ...state, isLoading: false, saveError: action.payload.error };
  }

  if (isType(action, checkEmailDetailsAction.failed)) {
    return { ...state, emailCheckError: action.payload.error, emailCheckSuccess: false };
  }

  if (isType(action, checkEmailDetailsAction.done)) {
    return { ...state, emailCheckError: null, emailCheckSuccess: true };
  }

  if (isType(action, setModalTypeAction)) {
    return { ...state, modalType: action.payload };
  }

  if (isType(action, setEtagAction)) {
    return { ...state, etag: action.payload };
  }
  if (isType(action, setForceSaveWithEtagAction)) {
    return { ...state, forceSaveWithEtag: action.payload };
  }

  if (isType(action, setProgressStepAction)) {
    return { ...state, currentStep: action.payload };
  }

  if (
    isType(action, getPersonalEmailJustificationsAction.started) ||
    isType(action, getPersonalEmailJustificationsAction.failed)
  ) {
    return { ...state, justifications: [], unjustifiedPersonalEmails: [] };
  }
  if (isType(action, getPersonalEmailJustificationsAction.done)) {
    return { ...state, ...action.payload.result, modalType: ModalType.PersonalEmailJustification };
  }

  if (isType(action, getCountriesAction.started) || isType(action, getCountriesAction.failed)) {
    return { ...state, countries: [] };
  }
  if (isType(action, getCountriesAction.done)) {
    return { ...state, countries: action.payload.result };
  }

  if (isType(action, getPersonByIdAction.done)) {
    return { ...state, person: action.payload.result };
  }
  if (isType(action, getPersonByIdAction.failed)) {
    return { ...state, person: null };
  }

  if (isType(action, setMainAddressAction)) {
    return { ...state, mainAddress: action.payload };
  }

  if (isType(action, checkForSimilarAction.done)) {
    return { ...state, similarContacts: [...action.payload.result], isLoading: false };
  }

  if (isType(action, checkForSimilarAction.started)) {
    return { ...state, isLoading: true };
  }

  if (isType(action, checkForSimilarAction.failed)) {
    return { ...state, isLoading: false, similarContacts: [] };
  }

  if (isType(action, endPositionsAction.done)) {
    return { ...state, endPositionLoading: false, endPositionsError: null };
  }

  if (isType(action, endPositionsAction.started)) {
    return { ...state, endPositionLoading: true, endPositionsError: null, isChangePosition: true };
  }

  if (isType(action, endPositionsAction.failed)) {
    return { ...state, endPositionLoading: false, endPositionsError: action.payload.error };
  }
  if (isType(action, resetContextAction.started)) {
    return initialContactCreatorState;
  }
  return state;
};
