import * as React from "react";
import { Button } from "@sgbs-ui/core";

interface Props {
  show: boolean;
  children?: React.ReactElement;
  onClose?: () => void;
  renderHeader?: () => React.ReactElement;
  renderFooter?: () => React.ReactElement;
}

const ModalCreateContact: React.FC<Props> = ({ show, children, onClose, renderHeader, renderFooter }: Props) => {
  React.useEffect(() => {
    if (show) {
      document.body.setAttribute("class", "modal-open");
    } else {
      document.body.setAttribute("class", "");
    }
  }, [show]);

  // in case when modal is componentWillUnmount
  React.useEffect(() => {
    return () => document.body.setAttribute("class", "");
  });

  const handleOnClose = (): void => {
    onClose?.();
  };

  return show ? (
    <>
      <div className="modal fade show modal-dialog-centered" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-lg flex-grow-1" role="document">
          <div className="modal-content shadow-max">
            <div className="modal-header">
              <h3 className="modal-title">Create contact</h3>
              <Button btnType="link" size="lg" icon="close" className="close action" onClick={handleOnClose} />
            </div>
            {renderHeader?.()}
            <div className="modal-body mt-3">{children}</div>
            <div className="modal-footer">{renderFooter?.()}</div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  ) : null;
};

export default ModalCreateContact;
