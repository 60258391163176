import * as React from "react";
import AddEmailsForm from "./AddEmailsForm";
import AddPhonesForm from "./AddPhonesForm";
import AddAddressesForm from "./AddAddressesForm";
import {
  ContactCreatorContext,
  ContactCreatorContextProviderProps,
} from "../contextProvider/ContactCreatorContextProvider";
import { FormattedMessage } from "react-intl";

const AdditionalInformationForm: React.VFC = () => {
  const { actions } = React.useContext(ContactCreatorContext) as ContactCreatorContextProviderProps;

  React.useEffect(() => {
    actions.getCountries();
  }, [actions]);

  return (
    <>
      <h4>
        <FormattedMessage id="global.additionalInformation" defaultMessage={"Additional information"} />
      </h4>
      <AddEmailsForm />
      <AddPhonesForm />
      <AddAddressesForm />
    </>
  );
};

export default AdditionalInformationForm;
