import { useFormikContext } from "formik";
import * as React from "react";
import { ContactCreatorFormModel, ModalType } from "../../../common/typings";
import { Button, Modal } from "@sgbs-ui/core";
import { ContactPosition } from "../../../api/contacts/contacts.typings";
import SimilarContact from "../../../common/components/SimilarContact/SimilarContact";
import AddOrUpdatePosition from "./AddOrUpdatePosition/AddOrUpdatePosition";
import { useChangePositionModal } from "./hooks/useChangePositionModal";
import { useChangePositionActions } from "./hooks/useChangePositionActions";
import { mapAddPositionToContactPosition } from "../../contextProvider/contactCreator.mapper";
import { FetchFn, useSgConnectFetch } from "@sg-widgets/react-core";
import { SGMContactScope } from "../../../common/sgConnectScopes";

export interface UpdatePosition {
  personId: string;
  contactIds: string[];
  contact: ContactCreatorFormModel;
}

const PositionChangeModal: React.VFC = () => {
  const { values } = useFormikContext<ContactCreatorFormModel>();
  const [payload, setPayload] = React.useState<UpdatePosition>();
  const [similars, isLoading, modalType, createContact, confirmUpdate, closeModal, sendLog] = useChangePositionActions(
    values,
    payload?.contactIds,
    payload?.personId
  );
  const [ref, step, setStep, title, subTitle] = useChangePositionModal(similars, values);

  const [selectedContact, setSelectedContact] = React.useState<ContactPosition | undefined>();
  const [contactToCreate, setContactToCreate] = React.useState<ContactPosition | undefined>();
  const fetch = useSgConnectFetch(SGMContactScope).fetch as FetchFn;

  const handleOnClose = (): void => {
    setStep("step1");
    closeModal();
    sendLog("close", "click on close modal or cancel");
  };

  const handleOnUpdate = async (): Promise<void> => {
    if (step === "step1") {
      setContactToCreate(await mapAddPositionToContactPosition(fetch, values));
      setStep("step2");
    } else {
      handleOnConfirmUpdate();
    }
  };

  const handleOnConfirmUpdate = (): void => {
    confirmUpdate();
  };

  const handleOnSelectChange = (personId: string, contactIds: string[]) => {
    setPayload({ personId, contactIds, contact: values });
  };

  const handleOnSelectContact = (id: string) => {
    const selected = similars.find(contact => contact.id === id);
    sendLog("select_position", "choose one of similar contact");
    setSelectedContact(selected);
  };

  const handleOnBack = () => {
    setStep("step1");
    sendLog("back", "click on back");
  };

  const renderActions = (): JSX.Element => {
    return (
      <div className="d-flex justify-content-between flex-grow-1">
        <div>
          <Button btnType="default" text="Cancel" disabled={isLoading} onClick={handleOnClose} />
        </div>
        <div>
          {step === "step1" ? (
            <Button
              color="flat-secondary"
              className="mr-1"
              text="No, I want to create a new contact"
              disabled={isLoading}
              onClick={createContact}
            />
          ) : (
            <Button
              color="flat-secondary"
              className="mr-1"
              text="Back"
              disabled={isLoading}
              onClick={() => setStep("step1")}
            />
          )}

          <Button
            color="primary"
            text={step === "step1" ? "Update" : "Confirm update"}
            disabled={isLoading || (step === "step1" && !selectedContact)}
            onClick={handleOnUpdate}
          />
        </div>
      </div>
    );
  };

  const renderHeader = (): JSX.Element => {
    return (
      <div className="d-flex justify-content-between flex-grow-1">
        <div className="d-flex flex-grow-1 align-items-center">
          {step === "step2" && (
            <div className="mr-2">
              <Button color="flat-secondary" icon="arrow_back" onClick={handleOnBack} />
            </div>
          )}

          <h4 className="modal-title text-black"> {title} </h4>
        </div>

        <div>
          <Button color="flat-secondary" icon="close" onClick={handleOnClose} />
        </div>
      </div>
    );
  };

  return modalType === ModalType.PositionChange ? (
    <div ref={ref}>
      <Modal
        show={modalType === ModalType.PositionChange}
        size="lg"
        isScrollable={true}
        renderFooterActions={renderActions}
        footerClassName="modal-footer"
        onClose={handleOnClose}
        renderHeaderActions={renderHeader}
      >
        <div className="text-medium font-weight-500"> {subTitle} </div>
        {step === "step1" &&
          similars.map(contact => (
            <SimilarContact
              key={contact.id}
              contact={contact}
              onSelect={handleOnSelectContact}
              active={contact.id === selectedContact?.id}
            />
          ))}

        {step === "step2" && !!selectedContact && !!selectedContact?.person?.id && (
          <AddOrUpdatePosition
            contact={selectedContact}
            contactToCreate={contactToCreate}
            personId={selectedContact.person.id}
            onSelectionChange={handleOnSelectChange}
          />
        )}
      </Modal>
    </div>
  ) : null;
};

export default PositionChangeModal;
