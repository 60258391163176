import * as React from "react";
import { ContactCreatorFormModel } from "../../common/typings";
import ExistingEmailErrorModal from "./ExistingEmailErrorModal";
import IncompatibleEmailDomainErrorModal from "./IncompatibleEmailDomainErrorModal";
import PersonalEmailModal from "./PersonalEmailModal";
import PositionChangeModal from "./PositionChange/PositionChangeModal";
import EditContactChangePositionModal from "./EditContactPositionChangeModal/EditContactPositionChangeModal";
import { ContactDbDto } from "../../api/contacts/contacts.typings";

interface Props {
  isLight?: boolean;
  isEdit?: boolean;
  contactId?: string;
  contact?: ContactDbDto;
  onConfirm?: (values: ContactCreatorFormModel) => void;
}

const ContactCreatorModals: React.FC<Props> = ({
  isLight = false,
  isEdit = false,
  contactId,
  contact,
  onConfirm,
}) => {
  return (
    <>
      <IncompatibleEmailDomainErrorModal
        isLight={isLight}
        isEdit={isEdit}
        contactId={contactId}
        onConfirm={onConfirm}
      />
      <ExistingEmailErrorModal />
      <PersonalEmailModal />
      <PositionChangeModal />
      {contact && <EditContactChangePositionModal contact={contact} />}
    </>
  );
};

export default ContactCreatorModals;
