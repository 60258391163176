import { Field, useFormikContext } from "formik";
import { map } from "lodash-es";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ContactCreatorFormModel } from "../../common/typings";
import { ContactMultiSelectPicker } from "../../ic-contact-multi-picker/ContactMultiSelectPicker";

const ConfidentialityForm: React.VFC = () => {
  const { values, setValues } = useFormikContext<ContactCreatorFormModel>();
  const { formatMessage } = useIntl();

  return (
    <>
      <h4>
        <FormattedMessage id="contact.confidentiality" defaultMessage={"Confidentiality"} />
      </h4>
      <div className="text-secondary font-weight-bold mb-1">
        <FormattedMessage id="contact.confidentiality.visibleBy" defaultMessage={"Contact visible by"} />
      </div>
      <div className="ml-2 mt-1">
        <div>
          <Field id="public-visibility" type="radio" name="visibility" value="Public" className="mr-2" />
          <label htmlFor="public-visibility">
            <FormattedMessage id="global.all" defaultMessage={"All"} />
            <span className="text-secondary">
              {" "}
              - <FormattedMessage id="contact.confidentiality.allIcUsers" defaultMessage={"All IC Anywhere users"} />
            </span>
          </label>
        </div>
        <div>
          <Field id="private-visibility" type="radio" name="visibility" value="Confidential" className="mr-2" />
          <label htmlFor="private-visibility">
            <FormattedMessage id="accessRights.restricted" defaultMessage={"Restricted"} />
            <span className="text-secondary">
              {" "}
              - <FormattedMessage id="contact.confidentiality.selectedOnly" defaultMessage={"Selected contacts only"} />
            </span>
          </label>
          {values.visibility === "Confidential" && (
            <ContactMultiSelectPicker
              placeholder={formatMessage({
                id: "global.picker.contacts.placeholder",
                defaultMessage: "Search and select contacts",
              })}
              isInternal={true}
              displayOption="none"
              selectedIds={values?.owners?.contactIds}
              onChange={contacts => setValues({ ...values, owners: { contactIds: map(contacts, ({ id }) => id) } })}
              top={true}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ConfidentialityForm;
