import * as React from "react";
import { Form, Formik } from "formik";
import { initialValues } from "./contants";
import {
  ContactCreatorContext,
  ContactCreatorContextProviderProps,
} from "../contextProvider/ContactCreatorContextProvider";
import { ContactCreatorFormModel, CreateLinkMode, Email, ProgressStep, ModalType } from "../../common/typings";
import ProgressBar from "./ProgressBar";
import { validationSchema } from "./validation";
import ContactCreatorModals from "../Modals/ContactCreatorModals";
import PersonalInformationForm from "./PersonalInformationForm";
import AdditionalInformationForm from "./AdditionalInformationForm";
import ConfidentialityForm from "./ConfidentialityForm";
import { Button } from "@sgbs-ui/core";
import { SavedPersonContact } from "../../common/typings/contacts.typings";
import Loading from "../../common/components/Loading/Loading";
import ModalCreateContact from "../../common/components/ModalCreateContact/ModalCreateContact";
import { Email as EmailDto, ContactDbDto } from "../../api/contacts/contacts.typings";
import { trim } from "lodash-es";
import { getFormInitialValues } from "../contextProvider/contactCreator.mapper";
import { ReactAPI } from "../ic-contact-creator";
import { useIntl } from "react-intl";

interface Props {
  isModal: boolean;
  isShown: boolean;
  mainEmail?: Email;
  isHideStepper?: boolean;
  isHideButtons?: boolean;
  isCancelShown?: boolean;
  personId?: string;
  accountId?: string;
  createLinkMode?: CreateLinkMode;
  positionChange?: boolean;
  onCreated: (contact: SavedPersonContact) => void;
  onCancel?: () => void;
  onError?: (message: string) => void;
  onStepChanged?: (progressStep: ProgressStep) => void;
  onCreateAccount?: (accountName?: string) => void;
}

// eslint-disable-next-line react/display-name
const ContactCreatorForm = React.forwardRef<ReactAPI, Props>(
  (
    {
      mainEmail = initialValues.mainEmail,
      onCreated,
      onCancel,
      onError,
      onStepChanged,
      onCreateAccount,
      isHideStepper,
      isHideButtons = false,
      isModal,
      isShown,
      isCancelShown,
      personId,
      accountId,
      createLinkMode,
      positionChange = true,
    }: Props,
    ref
  ) => {
    const { actions, context } = React.useContext(ContactCreatorContext) as ContactCreatorContextProviderProps;
    const {
      currentStep,
      contact,
      saveError,
      isLoading,
      etag,
      forceSaveWithEtag,
      emailCheckError,
      endPositionsError,
    } = context;
    const { formatMessage } = useIntl();

    React.useEffect(() => {
      if (personId) {
        actions.getPersonById(personId);
      }
    }, [personId]);

    React.useEffect(() => {
      if (contact) {
        onCreated?.(contact);
      }
    }, [contact]);

    React.useEffect(() => {
      if (saveError) {
        onError?.(saveError.userMessage ?? saveError.message ?? "An error has occurred. Please try again.");
      }
    }, [saveError]);

    React.useEffect(() => {
      if (endPositionsError) {
        onError?.("Could not end some positions, pleasy try to end them manually");
      }
    }, [endPositionsError]);

    React.useEffect(() => {
      if (emailCheckError) {
        onError?.(
          emailCheckError.userMessage ?? emailCheckError.message ?? "The request has failed. Please try again."
        );
      }
    }, [emailCheckError]);

    React.useEffect(() => {
      if (currentStep) {
        onStepChanged?.(currentStep);
      }
    }, [currentStep]);

    const handleOnCancel = (): void => {
      onCancel?.();
    };
    const onSubmit = async (contactCreatorFormModel: ContactCreatorFormModel) => {
      const { mainEmail, employeeOfAccountId, emails, civility, firstName, lastName } = contactCreatorFormModel;
      if (!employeeOfAccountId) {
        return;
      }
      const typeCivility = civility === "Group" ? civility : "Person";
      if (currentStep === ProgressStep.PersonalInformation) {
        const { value, personalEmailJustificationId } = mainEmail;
        const emails = [{ value: trim(value), personalEmailJustificationId }];
        actions.checkEmailDetails(
          emails,
          employeeOfAccountId,
          typeCivility,
          lastName,
          firstName,
          ProgressStep.AdditionalInformation
        );
      } else if (currentStep === ProgressStep.AdditionalInformation) {
        if (emails.length) {
          actions.checkEmailDetails(
            emails as EmailDto[],
            employeeOfAccountId,
            typeCivility,
            lastName,
            firstName,
            ProgressStep.Confidentiality
          );
        } else {
          actions.setProgressStep(ProgressStep.Confidentiality);
        }
      } else if (currentStep === ProgressStep.Confidentiality) {
        if (positionChange) {
          const data: ContactDbDto[] = await actions.checkForSimilar(contactCreatorFormModel);
          if (data && data.length > 0) {
            actions.setModalType(ModalType.PositionChange);
          } else {
            actions.createContact(contactCreatorFormModel, forceSaveWithEtag ? etag : undefined);
            actions.setProgressStep(ProgressStep.Done);
          }
        } else {
          actions.createContact(contactCreatorFormModel, forceSaveWithEtag ? etag : undefined);
          actions.setProgressStep(ProgressStep.Done);
        }
      }
    };

    const buttons: React.ReactElement = (
      <div className="mt-4 col-12 p-0">
        <Loading isLoading={isLoading} />
        <div className="d-flex flex-row-reverse">
          <Button
            size="lg"
            className="ml-3"
            type="submit"
            text={
              currentStep === ProgressStep.Confidentiality
                ? formatMessage({
                    id: "global.save",
                    defaultMessage: "Save",
                  })
                : formatMessage({
                    id: "global.next",
                    defaultMessage: "Next",
                  })
            }
            icon={currentStep === ProgressStep.Confidentiality ? undefined : "arrow_forward"}
            disabled={isLoading}
          />
          {isCancelShown && (
            <Button
              btnType="flat"
              color="secondary"
              size="lg"
              text={formatMessage({
                id: "global.cancel",
                defaultMessage: "Cancel",
              })}
              disabled={isLoading}
              onClick={handleOnCancel}
            />
          )}
        </div>
      </div>
    );

    const form = (
      <>
        <div className="col-12 p-0">
          {!isModal && !isHideStepper && <ProgressBar />}
          <ContactCreatorModals />
          {currentStep === ProgressStep.PersonalInformation && (
            <PersonalInformationForm
              accountEmitMode="always"
              createLinkMode={createLinkMode}
              onCreateAccount={onCreateAccount}
              accountId={accountId}
            />
          )}
          {currentStep === ProgressStep.AdditionalInformation && <AdditionalInformationForm />}
          {(currentStep === ProgressStep.Confidentiality || currentStep === ProgressStep.Done) && (
            <ConfidentialityForm />
          )}
        </div>
        {!isModal && !isHideButtons && buttons}
      </>
    );

    return (
      <Formik
        {...{
          initialValues: getFormInitialValues(mainEmail, accountId),
          onSubmit,
          validationSchema,
        }}
      >
        {({ submitForm }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          React.useImperativeHandle(
            ref,
            () => ({
              saveContact() {
                submitForm();
              },
            }),
            []
          );
          return (
            <Form>
              {isModal ? (
                <ModalCreateContact
                  show={isShown}
                  onClose={onCancel}
                  renderHeader={() => (
                    <div className="col-12 px-5">
                      <ProgressBar />
                    </div>
                  )}
                  renderFooter={() => buttons}
                >
                  {form}
                </ModalCreateContact>
              ) : (
                form
              )}
            </Form>
          );
        }}
      </Formik>
    );
  }
);

export default ContactCreatorForm;
