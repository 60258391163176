import { useFormikContext } from "formik";
import { first, map, some, reduce, toLower, keys } from "lodash-es";
import * as React from "react";
import FormGroup from "../../common/components/FormGroup/FormGroup";
import ReactModal from "../../common/components/ReactModal/ReactModal";
import { ContactCreatorFormModel, Email, ModalType } from "../../common/typings";
import {
  ContactCreatorContext,
  ContactCreatorContextProviderProps,
} from "../contextProvider/ContactCreatorContextProvider";

type EmailJustification = {
  [key: string]: number;
};
const PersonalEmailModal: React.VFC = () => {
  const { values, setValues } = useFormikContext<ContactCreatorFormModel>();
  const { actions, context } = React.useContext(ContactCreatorContext) as ContactCreatorContextProviderProps;
  const { modalType, justifications, unjustifiedPersonalEmails: unjustifiedEmails } = context;
  const [emailJustifications, setEmailJustifications] = React.useState<EmailJustification>({});

  React.useEffect(() => {
    const firstJustification = first(justifications)?.id || 0;
    const emails = reduce(
      map(unjustifiedEmails, ({ email }) => email),
      (prev, current) => ({ ...prev, [current]: firstJustification }),
      {}
    );
    setEmailJustifications(emails);
  }, [justifications, unjustifiedEmails]);

  const handleOnChange = (justificationId: number, key: string): void => {
    const currentJustifications: EmailJustification = { ...emailJustifications, [toLower(key)]: justificationId };
    setEmailJustifications(currentJustifications);
  };

  const handleOnClose = (): void => {
    actions.setModalType(null);
  };

  const getEmailWithJustification = (email: Email): Email => {
    const personalEmailJustificationId = emailJustifications[toLower(email.value)];
    return personalEmailJustificationId ? { ...email, personalEmailJustificationId } : email;
  };

  const handleOnSave = (): void => {
    const mainEmail = getEmailWithJustification(values.mainEmail);
    const emails = map(values.emails, getEmailWithJustification);
    setValues({ ...values, mainEmail, emails });
    handleOnClose();
  };

  const renderActions = (): JSX.Element => {
    return (
      <div className="modal-footer">
        <button className="btn btn-flat-secondary btn-lg" onClick={handleOnClose}>
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-warning btn-lg"
          disabled={some(emailJustifications, j => !j)}
          onClick={handleOnSave}
        >
          Save
        </button>
      </div>
    );
  };
  const warningMessage =
    "Personal emails should not be registered in iC Anywhere unless you have a valid business reason for it.";
  const description =
    "If you want to proceed anyway, please provide a justification below. You will be asked to update it every 3 months.";

  return (
    <ReactModal
      show={modalType === ModalType.PersonalEmailJustification}
      withHeader={true}
      withAction={true}
      size="md"
      renderActions={renderActions}
      titleClass="text-warning"
      borderColor="warning"
      onClose={handleOnClose}
      title="The email is a personal email"
    >
      <div className="mx-1">
        <div className="alert alert-outline-warning d-flex">
          <div className="pr-2 d-flex align-items-center">
            <i className="icon icon-sm">info_outline</i>
          </div>
          <div>
            <b>{warningMessage}</b>
          </div>
        </div>
        <div>
          <p>{description}</p>
        </div>
        {map(keys(emailJustifications), email => (
          <FormGroup key={email} className="col p-0" title={`${email} justification`}>
            <select
              className="form-control"
              defaultValue={0}
              data-testid="selectJustification"
              onChange={e => handleOnChange(parseInt(e?.currentTarget?.value ?? "0"), email)}
            >
              {map(justifications, ({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </FormGroup>
        ))}
      </div>
    </ReactModal>
  );
};

export default PersonalEmailModal;
