import * as React from "react";
import { FetchFn, useSgConnectFetch } from "@sg-widgets/react-core";
import { ContactType, Email, Error, ContactDbDto, ContactPosition } from "../../api/contacts/contacts.typings";
import {
  checkEmailDetailsApi,
  createContactApi,
  editContactApi,
  fetchIndividualContactById,
  getContactSuggestions,
  getPersonalEmailJustificationsApi,
  searchContacts,
  deleteContactsByIds,
  reactivateContact,
  editPostionByContactId,
  endPostionByContactIds,
} from "../../api/contacts/contacts.api";
import {
  checkEmailDetailsAction,
  contactCreatorReducer,
  ContactCreatorState,
  createContactAction,
  editContactAction,
  getContactSuggestionAction,
  getCountriesAction,
  getPersonalEmailJustificationsAction,
  getPersonByIdAction,
  initialContactCreatorState,
  setEtagAction,
  setForceSaveWithEtagAction,
  setMainAddressAction,
  setModalTypeAction,
  setProgressStepAction,
  checkForSimilarAction,
  endPositionsAction,
  resetContextAction,
} from "./contactCreator.reducer";
import { Address, ContactCreatorFormModel, ModalType, ProgressStep } from "../../common/typings";
import { ContactCreatorScope } from "../../common/sgConnectScopes";
import { mapToChangePositionDto, mapToContactDto, mapToCountries } from "./contactCreator.mapper";
import { getCountriesApi } from "../../api/countries/countries.api";
import { CountryDto } from "../../api/countries/countries.typings";
import { mapToContactSuggestion, mapToSearchCriteria } from "../../api/contacts/contacts.mapper";
import { isEmpty, orderBy, toUpper, groupBy } from "lodash-es";
import { SavedPersonContact } from "../../common/typings/contacts.typings";
import { getClientById } from "../../api/accounts/accounts.api";
import useContactDbRepository from "../../api/useContactDbRepository";
import { ApiRepository } from "@ic-anywhere/ic-dal";

export interface DispatchProps {
  checkEmailDetails: (
    emails: Email[],
    employeeOfAccountId: string,
    type: ContactType,
    name?: string,
    givenName?: string,
    step?: ProgressStep,
    onSuccess?: () => void
  ) => void;
  createContact: (contact: ContactCreatorFormModel, etag?: string, personId?: string, onSuccess?: () => void) => void;
  setModalType: (modalType: ModalType | null) => void;
  setProgressStep: (progressStep: ProgressStep) => void;
  getCountries: () => void;
  setForceSaveWithEtag: (isForce: boolean) => void;
  getPersonById: (personId: string) => void;
  editContact: (contactId: string, contact: ContactCreatorFormModel, etag?: string, onSuccess?: () => void) => void;
  getContactSuggestions: (email: string) => void;
  setMainAddress: (address: Address) => void;
  checkForSimilar: (contact: ContactCreatorFormModel) => Promise<ContactPosition[]>;
  addPosition: (contact: ContactCreatorFormModel, personId?: string, etag?: string, onSuccess?: () => void) => void;
  editPosition: (
    contactId: string,
    contact: ContactCreatorFormModel,
    etag?: string,
    onSuccess?: () => void,
    isDisabled?: boolean
  ) => void;
  endPositions: (contactIds: string[], isDelete?: boolean, isDisabled?: boolean, onSuccess?: () => void) => void;
  resetContext: () => void;
}

export interface ContactCreatorContextProviderProps {
  context: ContactCreatorState;
  actions: DispatchProps;
}

const handleErrors = (
  fetch: FetchFn,
  dispatch: React.Dispatch<any>,
  error: Error,
  action,
  contactId?: string
): void => {
  const { status, unjustifiedPersonalEmails } = error;
  if (status === 422) {
    if (!isEmpty(unjustifiedPersonalEmails)) {
      dispatch(action.failed({ error: null, params: {} }));
      getPersonalEmailJustificationsApi(fetch)
        .then(justifications => {
          if (justifications !== null) {
            dispatch(setModalTypeAction(ModalType.PersonalEmailJustification));
            dispatch(
              getPersonalEmailJustificationsAction.done({
                result: {
                  justifications: orderBy([...justifications, { id: 0, name: "" }], "id", "asc"),
                  unjustifiedPersonalEmails,
                },
                params: null,
              })
            );
          }
        })
        .catch(err => {
          dispatch(getPersonalEmailJustificationsAction.failed({ error: err.message, params: null }));
        });
    } else {
      dispatch(action.failed({ error, params: {} }));
    }
  } else if (error.status === 409) {
    dispatch(
      action.failed({
        error:
          action === editContactAction && contactId
            ? { ...error, contacts: error?.contacts?.filter(contact => contact.id !== contactId) }
            : error,
        contactId,
        params: {},
      })
    );
    dispatch(setModalTypeAction(ModalType.ExistingEmailError));
  } else if (error.status === 428) {
    dispatch(action.failed({ error, params: {} }));
    dispatch(setEtagAction(error.etag));
    dispatch(setModalTypeAction(ModalType.IncompatibleEmailDomain));
  } else {
    dispatch(action.failed({ error, params: {} }));
  }
};

export const getActions = (fetch: FetchFn, dispatch: React.Dispatch<any>, repo: ApiRepository): DispatchProps => {
  return {
    getContactSuggestions: (email: string) => {
      getContactSuggestions(fetch, email)
        .then(mapToContactSuggestion)
        .then(contactSuggestion => {
          dispatch(getContactSuggestionAction.done({ result: contactSuggestion, params: email }));
        })
        .catch(error => dispatch(getContactSuggestionAction.failed({ error: error.message, params: "" })));
    },
    getPersonById: (personId: string) => {
      fetchIndividualContactById(fetch, personId)
        .then(result => {
          dispatch(getPersonByIdAction.done({ result, params: personId }));
        })
        .catch(error => getPersonByIdAction.failed({ error: error.message, params: "" }));
    },
    createContact: (contact: ContactCreatorFormModel, etag?: string, personId?: string, onSuccess?: () => void) => {
      dispatch(createContactAction.started());
      const contactDto = mapToContactDto(contact, personId);
      createContactApi(fetch, contactDto, etag)
        .catch(error => {
          return createContactApi(fetch, contactDto, error.etag);
        })
        .then(result => {
          dispatch(createContactAction.done({ result }));
          onSuccess?.();
        })
        .catch(error => {
          dispatch(createContactAction.failed({ error }));
          handleErrors(fetch, dispatch, error, createContactAction);
        });
    },
    checkEmailDetails: (
      emails: Email[],
      employeeOfAccountId: string,
      type: ContactType,
      name?: string,
      givenName?: string,
      step = ProgressStep.AdditionalInformation,
      onSuccess?: () => void
    ) => {
      return checkEmailDetailsApi(fetch, { emails, employeeOfAccountId, type, name, givenName })
        .then(() => {
          dispatch(setProgressStepAction(step));
          dispatch(checkEmailDetailsAction.done({ result: true, params: {} }));
          onSuccess?.();
          return null;
        })
        .catch((error: Error) => {
          handleErrors(fetch, dispatch, error, checkEmailDetailsAction);
        });
    },
    setModalType: (modalType: ModalType | null) => {
      dispatch(setModalTypeAction(modalType));
    },
    setForceSaveWithEtag: (isForce: boolean) => {
      dispatch(setForceSaveWithEtagAction(isForce));
    },
    setProgressStep: (progressStep: ProgressStep) => {
      dispatch(setProgressStepAction(progressStep));
    },
    getCountries: () => {
      dispatch(getCountriesAction.started({}));
      getCountriesApi(fetch)
        .then((countries: CountryDto[]) => {
          dispatch(getCountriesAction.done({ result: mapToCountries(countries), params: {} }));
        })
        .catch(err => {
          dispatch(getCountriesAction.failed({ error: err.message, params: {} }));
        });
    },
    editContact: (contactId: string, contact: ContactCreatorFormModel, etag?: string, onSuccess?: () => void) => {
      dispatch(editContactAction.started());
      editContactApi(fetch, contactId, mapToContactDto(contact), etag)
        .then((result: SavedPersonContact) => {
          dispatch(editContactAction.done({ result: { ...result, email: contact.mainEmail.value } }));
          onSuccess?.();
        })
        .catch((error: Error) => {
          handleErrors(fetch, dispatch, error, editContactAction, contactId);
        });
    },
    setMainAddress: (address: Address) => {
      dispatch(setMainAddressAction(address));
    },
    checkForSimilar: (contact: ContactCreatorFormModel) => {
      dispatch(checkForSimilarAction.started());
      const term = `${contact.firstName} ${contact.lastName}`;
      return new Promise((resolve, reject) => {
        searchContacts(
          repo,
          mapToSearchCriteria({
            term,
            onlyActive: false,
            maxResultCount: 250,
            additionalFields: ["auditInformation", "jobType", "phones", "person"],
          })
        )
          .then(async data => {
            if (data && data.length > 0) {
              let filteredContacts = data.filter(
                ({ givenName, name }) =>
                  toUpper(contact.firstName).trim() === toUpper(givenName).trim() &&
                  toUpper(contact.lastName).trim() === toUpper(name).trim()
              );

              const orderedActiveContact = orderBy(
                filteredContacts.filter(pos => pos.isActive),
                [pos => pos.auditInformation?.createdOn],
                ["desc"]
              );
              const orderedInactiveContact = orderBy(
                filteredContacts.filter(pos => !pos.isActive),
                [pos => pos.auditInformation?.createdOn],
                ["desc"]
              );
              filteredContacts = [...orderedActiveContact, ...orderedInactiveContact];

              const groupedContact = groupBy(filteredContacts, "person.id");

              const filteredPositions: ContactPosition[] = Object.keys(groupedContact).map(
                key => groupedContact[key][0]
              );

              filteredPositions.forEach(async (c: ContactDbDto) => {
                const contactPosition: ContactPosition = { ...c };
                const client = c.employeeOfAccountId ? await getClientById(fetch, c.employeeOfAccountId) : undefined;
                if (client) {
                  contactPosition.account = client;
                }
                return contactPosition;
              });

              resolve(Promise.resolve(filteredPositions));
              dispatch(checkForSimilarAction.done({ result: filteredPositions }));
            } else {
              dispatch(checkForSimilarAction.failed({ error: null }));
              resolve([]);
            }
          })
          .catch(e => {
            dispatch(checkForSimilarAction.failed({ error: e }));
            reject(e);
          });
      });
    },
    addPosition: (contact: ContactCreatorFormModel, personId?: string, etag?: string, onSuccess?: () => void) => {
      dispatch(createContactAction.started());
      const contactDto = mapToContactDto(contact, personId);

      createContactApi(fetch, contactDto, etag)
        .then(result => {
          dispatch(createContactAction.done({ result }));
          onSuccess?.();
        })
        .catch(error => {
          dispatch(createContactAction.failed({ error }));
          handleErrors(fetch, dispatch, error, editContactAction);
        });
    },
    editPosition: (
      contactId: string,
      contact: ContactCreatorFormModel,
      etag?: string,
      onSuccess?: () => void,
      isDisabled = false
    ) => {
      const editContact = () => {
        editPostionByContactId(fetch, contactId, mapToChangePositionDto(contact), etag)
          .then((result: SavedPersonContact) => {
            dispatch(editContactAction.done({ result: { ...result, email: contact.mainEmail.value } }));
            onSuccess?.();
          })
          .catch((error: Error) => {
            handleErrors(fetch, dispatch, error, editContactAction, contactId);
          });
      };

      dispatch(editContactAction.started());
      isDisabled
        ? reactivateContact(fetch, contactId, etag)
            .then(() => editContact())
            .catch((error: Error) => {
              handleErrors(fetch, dispatch, error, editContactAction, contactId);
            })
        : editContact();
    },
    endPositions: (contactIds: string[], isDelete = false, isDisabled = false, onSuccess?: () => void) => {
      const endOrdDeletePosition = () =>
        (isDelete ? deleteContactsByIds(fetch, contactIds) : endPostionByContactIds(fetch, contactIds))
          .then(() => {
            dispatch(endPositionsAction.done({}));
            onSuccess?.();
          })
          .catch(e => {
            dispatch(endPositionsAction.failed({ error: e }));
          });

      dispatch(endPositionsAction.started());

      isDisabled
        ? reactivateContact(fetch, contactIds?.[0])
            .catch(error => {
              return reactivateContact(fetch, contactIds?.[0], error.etag);
            })
            .then(() => endOrdDeletePosition())
            .catch((error: Error) => {
              handleErrors(fetch, dispatch, error, endPositionsAction, contactIds?.[0]);
            })
        : endOrdDeletePosition();
    },
    resetContext: () => dispatch(resetContextAction.started()),
  };
};

export const ContactCreatorContext = React.createContext<ContactCreatorContextProviderProps | null>(null);

interface Props {
  initialState?: ContactCreatorState;
  actions?: DispatchProps;
  fetchFn?: FetchFn;
}

const ContactCreatorContextProvider: React.FC<Props> = ({
  children,
  initialState = initialContactCreatorState,
  actions,
  fetchFn,
}) => {
  const fetch = useSgConnectFetch(ContactCreatorScope).fetch as FetchFn;
  const [state, dispatch] = React.useReducer(contactCreatorReducer, initialState);
  const repo = useContactDbRepository();
  const actionsCached = React.useMemo(() => actions ?? getActions(fetch, dispatch, repo), [actions, fetch]);

  return (
    <ContactCreatorContext.Provider value={{ context: state, actions: actionsCached }}>
      {children}
    </ContactCreatorContext.Provider>
  );
};

export default ContactCreatorContextProvider;
