import React from "react";
import { ContactPosition } from "../../../../api/contacts/contacts.typings";
import { ContactCreatorFormModel } from "../../../../common/typings";

type Step = "step1" | "step2";

export const useChangePositionModal = (
  similars: ContactPosition[],
  contact: ContactCreatorFormModel
): [React.RefObject<HTMLDivElement>, Step, (step: Step) => void, string, string] => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [step, setStep] = React.useState<Step>("step1");
  const [title, setTitle] = React.useState<string>("");

  React.useEffect(() => {
    if (ref?.current) {
      setStep("step1");
    }
  }, [ref?.current]);

  React.useEffect(() => {
    if (step === "step1" && similars.length > 0) {
      setTitle(
        `There ${similars.length > 1 ? "are" : "is"} already ${similars.length} ${contact.firstName} ${
          contact.lastName
        } in our database`
      );
    }
    if (step === "step2") {
      setTitle("Update contact with a new work position");
    }
  }, [step, similars, contact]);

  const subTitle =
    step === "step1"
      ? similars.length > 1
        ? "Which contact would you like to update?"
        : "Would you like to update the existing contact?"
      : "You are currently adding this new position";

  return [ref, step, setStep, title, subTitle];
};
