import * as React from "react";
import { widgetize, WidgetPropsMapping } from "@sg-widgets/react-core";
import ContactCreatorContextProvider from "./contextProvider/ContactCreatorContextProvider";
import ContactCreatorContent from "./Form/ContactCreatorForm";
import { WithSGB4 } from "../common/components/WithSGB4";
import WidgetTracker from "../common/components/WidgetTracker";
import { SavedPersonContact } from "../common/typings/contacts.typings";
import { convertToBoolOrUndefined } from "../utils/strings/stringify";
import { FLAG_ICON_CSS_FILE } from "../common/constants";
import { CreateLinkMode, ProgressStep } from "../common/typings";
import { PropMapping } from "@sg-widgets/react-core/lib/props";
import AppIntlProvider from "../common/components/intl/AppInltProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { defaultQueryClientOptions } from "@ic-anywhere/ic-dal";

interface Props {
  onCreated: (contact: SavedPersonContact) => void;
  onCancel?: () => void;
  onError?: (message?: string) => void;
  onStepChanged?: (progressStep: ProgressStep) => void;
  onCreateAccount?: (accountName?: string) => void;
  showCancel?: string;
  isHideButtons?: string;
  isHideStepper?: string;
  personId?: string;
  accountId?: string;
  email?: string;
  createLinkMode?: CreateLinkMode;
  positionChange?: string;
  language?: string;
}

export interface ReactAPI {
  saveContact: () => void;
}

const TAG = "ic-contact-creator";

const IcContactCreatorWidget = React.forwardRef<ReactAPI, Props>(
  (
    {
      onCreated,
      onCancel,
      onError,
      onStepChanged,
      onCreateAccount,
      showCancel,
      isHideButtons,
      isHideStepper,
      personId,
      accountId,
      email,
      createLinkMode,
      positionChange,
      language,
    }: Props,
    ref
  ) => {
    const queryClient = new QueryClient({ defaultOptions: defaultQueryClientOptions });

    return (
      <AppIntlProvider language={language}>
        <WithSGB4 extraUrls={[FLAG_ICON_CSS_FILE]}>
          <QueryClientProvider client={queryClient}>
            <ContactCreatorContextProvider>
              <ContactCreatorContent
                personId={personId}
                accountId={accountId}
                mainEmail={{ value: email ?? "" }}
                isCancelShown={convertToBoolOrUndefined(showCancel)}
                isHideStepper={convertToBoolOrUndefined(isHideStepper)}
                isHideButtons={convertToBoolOrUndefined(isHideButtons) ?? false}
                isModal={false}
                isShown={false}
                onCreated={onCreated}
                onError={onError}
                onCancel={onCancel}
                onStepChanged={onStepChanged}
                onCreateAccount={onCreateAccount}
                createLinkMode={createLinkMode}
                positionChange={convertToBoolOrUndefined(positionChange)}
                ref={ref}
              />
            </ContactCreatorContextProvider>
            <WidgetTracker widgetName={TAG} />
          </QueryClientProvider>
        </WithSGB4>
      </AppIntlProvider>
    );
  }
);

widgetize(
  TAG,
  IcContactCreatorWidget,
  {
    personId: WidgetPropsMapping.asString(),
    accountId: WidgetPropsMapping.asString(),
    email: WidgetPropsMapping.asString(),
    showCancel: WidgetPropsMapping.asString(),
    isHideStepper: WidgetPropsMapping.asString(),
    isHideButtons: WidgetPropsMapping.asString(),
    language: WidgetPropsMapping.asString(),
    positionChange: WidgetPropsMapping.asString(),
    onCreated: WidgetPropsMapping.asEventEmitter("created"),
    onError: WidgetPropsMapping.asEventEmitter("error"),
    onCancel: WidgetPropsMapping.asEventEmitter("cancel"),
    onStepChanged: WidgetPropsMapping.asEventEmitter("step"),
    onCreateAccount: WidgetPropsMapping.asEventEmitter("create-account"),
    createLinkMode: WidgetPropsMapping.asString() as PropMapping<Props, CreateLinkMode>,
  },
  { neverInjectGlobalCss: true, exportReactAPI: { saveContact: "Save contact" } }
);
