import { CountryCode, getCountryCallingCode } from "libphonenumber-js";
import { CountryDto } from "../../api/countries/countries.typings";

import {
  Address,
  ContactCreatorFormModel,
  ContactCreatorFormModelDto,
  Country,
  Email,
  IdName,
  PepSpoType,
  Phone,
  VisibilityType,
} from "../../common/typings";
import { initialValues, pepSpoTypes, PhoneType } from "../Form/contants";
import { isStringEmpty } from "../../utils/strings/stringUtils";
import {
  ChangePositionDto,
  ContactDbDto,
  ContactPosition,
  EmailCdbDto,
  PhoneCdbDto,
} from "../../api/contacts/contacts.typings";
import { generateUID } from "@sgbs-ui/core";
import { head, map, slice, trim } from "lodash-es";
import { FetchFn } from "@sg-widgets/react-core";
import { getClientById } from "../../api/accounts/accounts.api";

export const mapToContactDto = (contact: ContactCreatorFormModel, personId?: string): ContactCreatorFormModelDto => {
  return {
    givenName: trim(contact.civility !== "Group" ? contact.firstName : ""),
    name: trim(contact.civility !== "Group" ? contact.lastName : contact.groupName),
    type: contact.civility !== "Group" ? "Person" : "Group",
    employeeOfAccountId: contact.employeeOfAccountId ?? "",
    civility: contact.civility !== "Group" ? contact.civility || null : null,
    title: isStringEmpty(contact.title) ? undefined : contact.title,
    jobTitle: trim(contact.jobTitle),
    jobType: contact.jobType,
    visibility: contact.visibility,
    owners: contact.owners,
    isPepOrSpo: contact.pepSpoType === "Yes" ? true : contact.pepSpoType === "No" ? false : undefined,
    addresses: map(contact.addresses, ({ street1, city, zipCode, country }) => ({
      street1,
      city,
      country: {
        iso3: country.iso3,
      },
      zipCode,
    })),
    emails: [
      {
        value: trim(contact?.mainEmail?.value),
        personalEmailJustificationId: contact?.mainEmail?.personalEmailJustificationId,
      },
      ...map(contact.emails, ({ value, personalEmailJustificationId }) => ({
        value: trim(value),
        personalEmailJustificationId,
      })),
    ],
    phones: map(contact.phones, ({ type, number }) => ({
      type: type as PhoneType,
      value: `${number}`,
    })),
    personId: personId ?? contact.person?.id,
  };
};

export const mapToChangePositionDto = (contact: ContactCreatorFormModel): ChangePositionDto => {
  return {
    jobTitle: trim(contact.jobTitle),
    jobType: contact.jobType,
    mainEmail: contact.mainEmail.value,
    employer: {
      id: contact.employeeOfAccountId,
    },
  };
};

export const mapToCountries = (countries: CountryDto[]): Country[] => {
  return map(countries, ({ name, iso2, iso3 }: CountryDto) => {
    let areaCode;
    try {
      areaCode = getCountryCallingCode(iso2 as CountryCode) as string;
    } catch {
      areaCode = "";
    }
    return { name, iso2, iso3, areaCode };
  });
};

const mapContactPhone = (phones: PhoneCdbDto[], countries: Country[]): Phone[] =>
  phones?.map(phone => {
    const country = countries.find(({ iso2 }) => iso2 === phone.countryCodeIso2) || {
      iso2: "FR",
      iso3: "FRA",
      areaCode: "+33",
      name: "France",
    };

    return {
      iso2: phone.countryCodeIso2,
      type: phone.type,
      number: phone.value,
      areaCode: country.areaCode,
    };
  });

export const mapToEmailsWithoutJustifications = (emails: EmailCdbDto[] | undefined): EmailCdbDto[] | [] => {
  return (
    emails?.map(email => ({
      ...email,
      personalEmailJustification: undefined,
      personalEmailJustificationId: undefined,
      personalEmailJustificationName: undefined,
    })) ?? []
  );
};

export const mapToEditContactModel = (
  contact: ContactDbDto | null,
  countries: Country[],
  jobTypesReferentials: IdName<string>[] | undefined
): ContactCreatorFormModel => {
  const mainEmail = head(contact?.emails);
  const otherEmails = slice(contact?.emails, 1);
  const phones = mapContactPhone(contact?.phones ?? [], countries);
  const emails = map(otherEmails, ({ value, personalEmailJustificationId }) => ({
    value,
    personalEmailJustificationId,
  }));

  const civility = contact?.type === "Group" ? contact?.type : contact?.civility;

  return {
    person: undefined,
    employeeOfAccountId: contact?.employeeOfAccountId ?? "",
    civility: civility ?? "",
    title: contact?.title ?? "",
    firstName: contact?.givenName ?? "",
    lastName: contact?.name ?? "",
    groupName: contact?.name ?? "",
    mainEmail: {
      value: mainEmail?.value ?? "",
      personalEmailJustificationId: mainEmail?.personalEmailJustificationId ?? undefined,
    },
    emails: emails ?? [],
    jobTitle: contact?.jobTitle ?? "",
    jobType:
      jobTypesReferentials?.find(job => job.id.toLowerCase() === contact?.jobType?.toLocaleLowerCase())?.id ?? "",
    pepSpoType: pepSpoTypes.find(pep => pep.value === contact?.isPepOrSpo)?.label as PepSpoType,
    addresses: contact?.addresses?.map(address => ({ ...address, id: generateUID() })) as Address[],
    phones: phones as any,
    visibility: contact?.visibility as VisibilityType,
    owners: contact?.owners,
  };
};

export const getFormInitialValues = (email: Email, accountId?: string): ContactCreatorFormModel => {
  return { ...initialValues, mainEmail: email, employeeOfAccountId: accountId };
};

export const mapAddPositionToContactPosition = async (
  fetch: FetchFn,
  contact: ContactCreatorFormModel
): Promise<ContactPosition> => {
  const account = await getClientById(fetch, contact.employeeOfAccountId ?? "");
  return {
    id: "",
    jobTitle: trim(contact.jobTitle),
    jobType: contact.jobType,
    emails: [{ value: contact.mainEmail.value, type: "Professional" }],
    account: account,
    employeeOf: account,
    person: contact.person,
  };
};
