import React from "react";
import { ContactPosition } from "../../../../api/contacts/contacts.typings";
import SimilarContact from "../../../../common/components/SimilarContact/SimilarContact";
import AddNewPositionCard from "./cards/AddNewPosition";
import CloseAndUpdatePositionCard from "./cards/CloseAndUpdate";
import { useSgConnectFetch, FetchFn } from "@sg-widgets/react-core";
import { SGMContactScope } from "../../../../common/sgConnectScopes";
import { useFetchPositions } from "../../../../ic-work-positions/useFetchPositions";
import { Loader } from "@sgbs-ui/core";

interface AddOrUpdatePositionProps {
  personId: string | undefined;
  contact: ContactPosition;
  contactToCreate?: ContactPosition;
  isUpdate?: boolean;
  onSelectionChange: (personId: string, contactIds: string[]) => void;
}

const AddOrUpdatePosition: React.FC<AddOrUpdatePositionProps> = ({
  personId,
  contact,
  contactToCreate,
  isUpdate = false,
  onSelectionChange,
}: AddOrUpdatePositionProps) => {
  const fetch = useSgConnectFetch(SGMContactScope).fetch as FetchFn;
  const [isLoading, data] = useFetchPositions(fetch, !isUpdate ? contact.id : undefined, personId, true);
  const [positions, setPositions] = React.useState<ContactPosition[]>([]);

  React.useEffect(() => {
    if (contact) {
      const copy = data && !isUpdate ? data : [];
      const ordered = [...copy.filter(pos => pos.id !== contact.id && pos.isActive)];
      const firstContact = !isUpdate ? copy.find(pos => pos.id === contact.id) : contact;
      if (!!firstContact) {
        ordered.unshift(firstContact);
      }
      setPositions([...ordered]);
    }
  }, [data, contact, isUpdate]);

  const [isNewPositionActive, chooseNewPosition] = React.useState<boolean>(false);
  const [isUpdatePositionActive, chooseUpdatePosition] = React.useState<boolean>(true);

  const [selectedContactIds, setSelectedContacts] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (selectedContactIds && personId) {
      onSelectionChange(personId, selectedContactIds);
    }
  }, [selectedContactIds, personId]);

  const handleOnSelectUpdate = (contactIds: string[]) => {
    chooseNewPosition(false);
    chooseUpdatePosition(true);
    setSelectedContacts(contactIds);
  };

  const handleOnSelectNew = () => {
    chooseNewPosition(true);
    chooseUpdatePosition(false);
    setSelectedContacts([]);
  };

  return (
    <div>
      {contactToCreate && (
        <>
          <SimilarContact contact={contactToCreate} />
          <span className="mt-3">To</span>
        </>
      )}
      <SimilarContact contact={contact} />

      <Loader isVisible={isLoading} />
      {!isLoading && positions.length > 0 && (
        <>
          <h5 className="spacing-mt-1">Which action do you want to do?</h5>
          {!isUpdate && (
            <CloseAndUpdatePositionCard
              contact={contact}
              positions={positions}
              onSelect={handleOnSelectUpdate}
              active={isUpdatePositionActive}
            />
          )}

          <AddNewPositionCard onSelect={handleOnSelectNew} active={isNewPositionActive} />
        </>
      )}
      {!isLoading && positions.length === 0 && (
        <>
          <h5 className="spacing-mt-1">Do you confirm update work position?</h5>
        </>
      )}
    </div>
  );
};

export default AddOrUpdatePosition;
