import { Field, FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { find, isEmpty, map, size } from "lodash-es";
import * as React from "react";
import { ContactCreatorFormModel, Country, Phone } from "../../common/typings";
import { MaxElementOfOptionalInput, phoneTypes } from "./contants";
import {
  ContactCreatorContext,
  ContactCreatorContextProviderProps,
} from "../contextProvider/ContactCreatorContextProvider";
import CountryField from "../../common/components/Fields/CountryField";
import PhoneField from "../../common/components/Fields/PhoneField";
import { useIntl } from "react-intl";
import { Loader } from "@sgbs-ui/core";

export const getDefaultPhone = (countries: Country[], employeeOfAccountIso3?: string): Phone => {
  if (isEmpty(employeeOfAccountIso3)) {
    return {
      type: "Business",
      number: "",
      iso2: "FR",
      areaCode: "33",
    };
  }
  const defaultCountry = find(countries, c => c.iso3 === employeeOfAccountIso3)!;
  return {
    type: "Business",
    number: "",
    iso2: defaultCountry?.iso2 ?? "FR",
    areaCode: defaultCountry?.areaCode ?? "33",
  };
};

const AddPhonesForm: React.VFC = () => {
  const {
    context: { countries, isLoading },
  } = React.useContext(ContactCreatorContext) as ContactCreatorContextProviderProps;
  const { values } = useFormikContext<ContactCreatorFormModel>();
  const { formatMessage } = useIntl();

  return (
    <>
      <Loader isVisible={isLoading} />
      {!isLoading && (
        <div className="form-group">
          <div className="text-secondary font-weight-bold mb-1">
            {formatMessage({
              id: "global.phoneNumber",
              defaultMessage: "Phone number",
            })}{" "}
            (
            {formatMessage({
              id: "global.optional",
              defaultMessage: "optional",
            })}
            )
          </div>
          <FieldArray
            name="phones"
            render={({ push, remove }: FieldArrayRenderProps) => (
              <>
                {map(values.phones, (_, index) => {
                  return (
                    <div key={index}>
                      <Field as="select" name={`phones[${index}].type`} className="form-control col-3 mr-3">
                        {map(phoneTypes, ({ label }) => (
                          <option key={label} value={label}>
                            {label}
                          </option>
                        ))}
                      </Field>
                      <div className="d-flex my-2">
                        <div className="col-3 mr-3 px-0">
                          <CountryField fieldId={`phones[${index}].iso2`} countries={countries} />
                        </div>
                        <div className="col">
                          <PhoneField fieldId={`phones[${index}]`} />
                        </div>
                        <i
                          className="icon icon-md text-secondary col-auto px-0"
                          style={{ cursor: "pointer" }}
                          onClick={() => remove(index)}
                        >
                          delete
                        </i>
                      </div>
                    </div>
                  );
                })}
                {size(values.phones) < MaxElementOfOptionalInput.PHONE && (
                  <button
                    type="button"
                    className="btn btn-lg sgbs-btn-default btn-icon-text w-100 mb-3"
                    onClick={() => push(getDefaultPhone(countries, values.employeeOfAccountIso3))}
                  >
                    <i className="icon">add</i>
                    <span>
                      {formatMessage({
                        id: "global.newPhoneNumber",
                        defaultMessage: "New phone number",
                      })}
                    </span>
                  </button>
                )}
              </>
            )}
          />
        </div>
      )}
    </>
  );
};

export default AddPhonesForm;
