import { LabelValue } from "@ic-anywhere/ic-dal";
import { useIntl } from "react-intl";

const useCivilities = (): Array<LabelValue<string>> => {
  const { formatMessage } = useIntl();

  const mrLabel = formatMessage({ id: "contact.civility.mr", defaultMessage: "Mr" });
  const msLabel = formatMessage({ id: "contact.civility.ms", defaultMessage: "Ms" });
  const mxLabel = formatMessage({ id: "contact.civility.mx", defaultMessage: "Mx" });

  const civilityLabelsValues: Array<LabelValue<string>> = [
    { value: "Mr", label: mrLabel },
    { value: "Ms", label: msLabel },
    { value: "Mx", label: mxLabel },
  ];

  return civilityLabelsValues;
};

export default useCivilities;
