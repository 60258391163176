import { array, object, string } from "yup";
import { defaultEmailPattern } from "./contants";
import { parsePhoneNumber } from "libphonenumber-js";
import { size } from "lodash-es";
import { isStringEmpty } from "../../utils/strings/stringUtils";

export const checkFirstAndLastName = (value?: string | null, civility?: string): boolean =>
  civility === "Group" || !isStringEmpty(value);

export const validationSchema = object().shape({
  personId: string().optional(),
  employeeOfAccountId: string()
    .nullable(true)
    .required("Account is mandatory"),
  civility: string()
    .nullable(true),
  firstName: string()
    .nullable(true)
    .test("civility_not_group", "First name is mandatory", (value, context) =>
      checkFirstAndLastName(value, (context as any).parent.civility)
    ),
  lastName: string()
    .nullable(true)
    .test("civility_not_group", "Last name is mandatory", (value, context) =>
      checkFirstAndLastName(value, (context as any).parent.civility)
    ),
  groupName: string()
    .nullable(true)
    .test("civility_group", "Name is mandatory", (value, context) => {
      const civility = (context as any).parent.civility;
      return !isStringEmpty(value) || civility !== "Group";
    }),
  jobTitle: string()
    .nullable(true)
    .trim()
    .required("Job title is mandatory"),
  jobType: string()
    .nullable(true)
    .required("Job type is mandatory"),
  mainEmail: object().shape({
    value: string()
      .nullable(true)
      .required("Email is mandatory")
      .trim()
      .matches(defaultEmailPattern, "Incorrect email format"),
  }),
  emails: array().of(
    object().shape({
      value: string()
        .nullable(true)
        .required("Email is mandatory")
        .trim()
        .matches(defaultEmailPattern, "Incorrect email format")
        .test({
          exclusive: false,
          message: "Additional email address should be different of professional email",
          test: (value, context) => {
            const mainEmail: string = (context as any)?.from[1]?.value?.mainEmail?.value;
            return mainEmail?.toLocaleLowerCase() !== value?.toLocaleLowerCase();
          },
        }),
    })
  ),
  addresses: array().of(
    object().shape({
      country: object().shape({
        iso3: string().required("Country is mandatory"),
      }),
    })
  ),
  phones: array().of(
    object().shape({
      number: string()
        .required("Phone number is mandatory")
        .test({
          name: "check_phone",
          exclusive: false,
          message: "Wrong phone format for current country",
          test: (value, context) => {
            const countryIso2 = (context as any)?.parent?.iso2;
            if (size(value) < 2 || !countryIso2) {
              return false;
            }

            const parsed = parsePhoneNumber(value as string, countryIso2);

            return parsed?.isValid() && parsed.country === countryIso2;
          },
        }),
    })
  ),
});
