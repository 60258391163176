import * as React from "react";
import ReactModal from "../ReactModal/ReactModal";

interface ExistingEmailErrorModalProps {
  show: boolean;
  contactLabel: string;
  onClose: () => void;
}

const ExistingEmailErrorModal: React.FC<ExistingEmailErrorModalProps> = ({ show, contactLabel, onClose }) => {
  const renderActions = (): JSX.Element => {
    return (
      <div className="modal-footer">
        <button className="btn btn-danger btn-lg" onClick={onClose}>
          Close
        </button>
      </div>
    );
  };

  return (
    <ReactModal
      show={show}
      withHeader={true}
      withAction={true}
      size="md"
      renderActions={renderActions}
      titleClass="text-danger"
      borderColor="danger"
      onClose={onClose}
      title="Existing email"
    >
      <div className="mb-2">This email address is already associated to the following contact:</div>
      <div>{contactLabel}</div>
    </ReactModal>
  );
};

export default ExistingEmailErrorModal;
