import { map, size } from "lodash-es";
import * as React from "react";
import ReactModal from "../../common/components/ReactModal/ReactModal";
import { ContactCreatorFormModel, ModalType, ProgressStep } from "../../common/typings";
import {
  ContactCreatorContext,
  ContactCreatorContextProviderProps,
} from "../contextProvider/ContactCreatorContextProvider";
import { Button } from "@sgbs-ui/core";
import { useFormikContext } from "formik";

interface Props {
  isLight?: boolean;
  isEdit?: boolean;
  contactId?: string;
  onConfirm?: (values: ContactCreatorFormModel) => void;
}

const IncompatibleEmailDomainErrorModal: React.FC<Props> = ({
  isLight = false,
  isEdit = false,
  contactId = "",
  onConfirm,
}) => {
  const { actions, context } = React.useContext(ContactCreatorContext) as ContactCreatorContextProviderProps;
  const { modalType, emailCheckError, saveError, etag, isChangePosition } = context;
  const { values } = useFormikContext<ContactCreatorFormModel>();

  const handleOnClose = (): void => {
    actions.setModalType(null);
  };

  const handleOnConfirm = (): void => {
    if (onConfirm) {
      onConfirm(values);
    } else if (isLight || isChangePosition) {
      actions.createContact(values, etag, values.person?.id);
    } else if (isEdit) {
      actions.editContact(contactId, values, etag);
    } else {
      if (context.currentStep === ProgressStep.PersonalInformation) {
        actions.setProgressStep(ProgressStep.AdditionalInformation);
      } else {
        actions.setProgressStep(ProgressStep.Confidentiality);
      }
    }
    actions.setForceSaveWithEtag(true);
    handleOnClose();
  };

  const handleOnCancel = (): void => {
    actions.setForceSaveWithEtag(false);
    handleOnClose();
  };

  const renderActions = (): React.ReactElement => {
    return (
      <div className="modal-footer">
        <Button btnType="flat" color="secondary" size="lg" text="Cancel" onClick={handleOnCancel} />
        <Button color="warning" size="lg" text="Continue anyway" onClick={handleOnConfirm} />
      </div>
    );
  };

  const emailError = emailCheckError ?? saveError;
  const isMultiErrors = emailError?.preConditions?.length && emailError.preConditions.length > 1;
  return (
    <ReactModal
      show={modalType === ModalType.IncompatibleEmailDomain}
      withHeader={true}
      withAction={true}
      size="md"
      renderActions={renderActions}
      titleClass="text-warning"
      borderColor="warning"
      onClose={handleOnClose}
      title={emailError?.userMessage}
    >
      <>
        {emailError?.preConditions?.map((pc, index) => (
          <div className="mb-4" key={index}>
            {isMultiErrors ? <h6>{`Error ${index + 1} :`}</h6> : <></>}
            <div className="mb-2">{pc.label}</div>
            {pc.attributes?.domainNameConflicts && (
              <>
                {map(pc.attributes.domainNameConflicts, ({ domainName, accounts }, index) => (
                  <div key={index} className="mb-2 font-weight-bold">
                    {domainName} -{" "}
                    {map(accounts, ({ id, friendlyName, level, countryCode }, index) => (
                      <span key={id}>
                        {friendlyName} ({level}) {countryCode}
                        {index === size(accounts) - 1 ? "" : " - "}
                      </span>
                    ))}
                  </div>
                ))}
                <div>Please ensure the selected account and the entered email are the right ones.</div>
              </>
            )}
          </div>
        ))}
      </>
    </ReactModal>
  );
};

export default IncompatibleEmailDomainErrorModal;
