import React from "react";

type Step = "step1" | "step2";

export const useEditContactPositionModal = (): [
  React.RefObject<HTMLDivElement>,
  Step,
  (step: Step) => void,
  string,
  string
] => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [step, setStep] = React.useState<Step>("step1");
  const [title, setTitle] = React.useState<string>("");

  React.useEffect(() => {
    if (step === "step1") {
      setTitle(`Is this a new work position?`);
    }
    if (step === "step2") {
      setTitle("Update contact with a new work position");
    }
  }, [step]);

  const subTitle =
    step === "step1"
      ? "Your contact seems to have a new work position. If this is correct, please update your contact by creating a new work position."
      : "You are currently adding this new position";

  return [ref, step, setStep, title, subTitle];
};
