import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ProgressStep } from "../../common/typings";
import {
  ContactCreatorContext,
  ContactCreatorContextProviderProps,
} from "../contextProvider/ContactCreatorContextProvider";

const ProgressBar: React.VFC = () => {
  const { context, actions } = React.useContext(ContactCreatorContext) as ContactCreatorContextProviderProps;
  const { currentStep } = context;

  const goToStep = (step: ProgressStep) => () => {
    if (step < currentStep) {
      actions.setProgressStep(step);
    }
  };

  const cursorForStep = (step: ProgressStep) => (step < currentStep ? { cursor: "pointer" } : { cursor: "default" });

  return (
    <ul className="sgbs-stepper text-info spacing-mb-1">
      <li
        onClick={goToStep(ProgressStep.PersonalInformation)}
        className={`sgbs-stepper-step ${currentStep === ProgressStep.PersonalInformation ? "current" : "fill"}`}
      >
        <div className="sgbs-stepper-step-inner">
          <div className="sgbs-stepper-indicator">
            <i className="icon" style={cursorForStep(ProgressStep.PersonalInformation)}>
              {currentStep === ProgressStep.PersonalInformation ? "fiber_manual_record" : "check"}
            </i>
          </div>
        </div>
        <span className="sgbs-stepper-label">
          <FormattedMessage id="contact.personalInformation" defaultMessage={"Personal information"} />
        </span>
      </li>
      <li
        onClick={goToStep(ProgressStep.AdditionalInformation)}
        className={`sgbs-stepper-step ${
          currentStep === ProgressStep.AdditionalInformation
            ? "current"
            : currentStep === ProgressStep.Confidentiality || currentStep === ProgressStep.Done
            ? "fill"
            : "inactive"
        }`}
      >
        <div className="sgbs-stepper-step-inner">
          <div className="sgbs-stepper-indicator">
            {currentStep !== ProgressStep.PersonalInformation && (
              <i className="icon" style={cursorForStep(ProgressStep.AdditionalInformation)}>
                {currentStep === ProgressStep.AdditionalInformation ? "fiber_manual_record" : "check"}
              </i>
            )}
          </div>
        </div>
        <span className="sgbs-stepper-label">
          <FormattedMessage id="global.additionalInformation" defaultMessage={"Additional information"} />
        </span>
      </li>
      <li
        onClick={goToStep(ProgressStep.Confidentiality)}
        className={`sgbs-stepper-step ${
          currentStep === ProgressStep.Confidentiality
            ? "current"
            : currentStep === ProgressStep.Done
            ? "fill"
            : "inactive"
        }`}
      >
        <div className="sgbs-stepper-step-inner">
          <div className="sgbs-stepper-indicator">
            <i className="icon" style={cursorForStep(ProgressStep.Confidentiality)}>
              {currentStep === ProgressStep.Done
                ? "check"
                : currentStep === ProgressStep.Confidentiality
                ? "fiber_manual_record"
                : "inactive"}
            </i>
          </div>
        </div>
        <span className="sgbs-stepper-label">
          <FormattedMessage id="contact.confidentiality" defaultMessage={"Confidentiality"} />
        </span>
      </li>
    </ul>
  );
};

export default ProgressBar;
