import React from "react";
import { ContactPosition } from "../../../../api/contacts/contacts.typings";
import { ContactCreatorFormModel, ProgressStep, ModalType } from "../../../../common/typings";
import {
  ContactCreatorContext,
  ContactCreatorContextProviderProps,
} from "../../../contextProvider/ContactCreatorContextProvider";
import { useWidgetConfiguration } from "@sg-widgets/react-core";
import { WidgetEnv, logMessage } from "../../../../common/logger/logger";

export const useChangePositionActions = (
  contact: ContactCreatorFormModel,
  contactIds: string[] | undefined,
  personId: string | undefined
): [
  ContactPosition[],
  boolean,
  ModalType | null,
  () => void,
  () => void,
  () => void,
  (name: string, description: string) => void
] => {
  const { actions, context } = React.useContext(ContactCreatorContext) as ContactCreatorContextProviderProps;
  const { isLoading, endPositionLoading, similarContacts: similars, etag, forceSaveWithEtag, modalType } = context;

  const widgetConfiguration = useWidgetConfiguration();
  const env = widgetConfiguration?.environment as WidgetEnv;

  const sendLog = (name: string, description: string) => {
    logMessage({
      name: `modal_create_contact_similars_${name}`,
      feature: "ic web-component ic-contact-creator",
      description,
      event: `modal_create_contact_similars_${name}`,
      env,
    });
  };

  const createContact = (id?: string): void => {
    actions.createContact(contact, forceSaveWithEtag ? etag : undefined, id);
    actions.setProgressStep(ProgressStep.Done);
  };

  const endPositions = (ids: string[]): void => {
    actions.endPositions(ids);
    sendLog("close_position", "close positions when creating new contact");
  };

  const closeModal = (): void => {
    actions.setModalType(null);
  };

  const confirmUpdate = (): void => {
    createContact(personId);
    if (contactIds) {
      endPositions(contactIds);
    }
    sendLog("confirm_update_position", "click on no i want new contact button when updating position");
    closeModal();
  };

  const handleCreateContact = (): void => {
    sendLog("create_new_contact", "click on confirm update button even when there are other similar contacts");
    createContact();
    closeModal();
  };

  return [
    similars,
    isLoading || endPositionLoading,
    modalType,
    handleCreateContact,
    confirmUpdate,
    closeModal,
    sendLog,
  ];
};
