import * as React from "react";
import { InvalidFeedback } from "../InvalidFeedback/InvalidFeedback";

interface Props {
  title: string;
  className?: string;
  touched?: boolean;
  errors?: string;
  isWarning?: boolean;
}

const FormGroup: React.FC<Props> = ({ title, className = "", touched, errors, children, isWarning }) => {
  return (
    <div className={`form-group ${className}`}>
      <div className="text-secondary font-weight-bold mb-1">{title}</div>
      {children}
      <InvalidFeedback errorMessage={touched && errors ? errors : ""} isWarning={isWarning} />
    </div>
  );
};

export default FormGroup;
