import React from "react";
import { FetchFn } from "@sg-widgets/react-core";
import { ContactPosition, SearchContactDbProperties } from "../api/contacts/contacts.typings";
import { getContactById, getContactsByPersonId, getPositionsByPersonId } from "../api/contacts/contacts.api";
import { getClientById } from "../api/accounts/accounts.api";
import { orderBy } from "lodash-es";

const fields: SearchContactDbProperties[] = [
  "account",
  "jobTitle",
  "jobType",
  "title",
  "civility",
  "emails",
  "country",
  "auditInformation",
  "person",
  "addresses",
  "phones",
  "isPepOrSpo",
  "visibility",
  "owners",
];

export const useFetchPositions = (
  fetch: FetchFn,
  contactId: string | undefined,
  personId?: string,
  onlyActive = false
): [boolean, ContactPosition[] | undefined, () => void] => {
  const [contactPositions, setContactPositions] = React.useState<ContactPosition[]>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const getByPersonId = (personId: string) =>
    getPositionsByPersonId(fetch, personId, fields).then(contacts =>
      contacts.map(async (position: ContactPosition) => {
        position.employeeOfAccountId &&
          (await getClientById(fetch, position.employeeOfAccountId).then(client => (position.account = client)));
        const orderedActiveContact = orderBy(
          contacts.filter(c => c.isActive),
          [c => c.auditInformation?.createdOn],
          ["desc"]
        );
        const orderedInactiveContact = orderBy(
          contacts.filter(c => !c.isActive),
          [c => c.auditInformation?.createdOn],
          ["desc"]
        );

        setContactPositions([...orderedActiveContact, ...orderedInactiveContact]);
      })
    );
  const fetchPositions = () => {
    if (personId) {
      setIsLoading(true);
      getByPersonId(personId).finally(() => setIsLoading(false));
    } else if (contactId) {
      setIsLoading(true);
      getContactById(fetch, contactId, fields, onlyActive)
        .then(contact => {
          contact.person?.id && getByPersonId(contact.person?.id);
          setContactPositions([contact]);
        })
        .finally(() => setIsLoading(false));
    }
  };
  React.useEffect(() => {
    fetchPositions();
  }, [fetch, contactId, onlyActive]);

  const reload = () => {
    fetchPositions();
  };
  return [isLoading, contactPositions, reload];
};
