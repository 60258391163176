import { Person } from "@ic-anywhere/ic-dal";
import { SGBSSize } from "@sgbs-ui/core";
import { OwnersCdbDto } from "../api/contacts/contacts.typings";
import { PhoneType } from "../ic-contact-creator/Form/contants";

export type ContactDisplayOption = "email" | "jobtitle" | "none";

export type CreateLinkMode = "redirect" | "emit" | "none";

export type EmitOnChangeMode = "user" | "always" | "not-empty";

export type AccountDisplayOption = "none" | "bdr-legal-id" | "bdr-commercial-id" | "city";

export type TitleTextSize = "h1" | "h2" | "h3" | "h4" | "h5";

export interface ErrorPickerProps<E = string | boolean> {
  inError?: E;
  errorMessage?: string;
}

export interface CreateLinkPickerProps {
  createLinkMode?: CreateLinkMode;
  createActionText?: string;
  onCreateLinkClicked?: (term?: string) => void;
}

export interface CommonPickerProps<Output, B = boolean> extends ErrorPickerProps<B> {
  id?: string;
  maxResultCount?: number;
  placeholder?: string;
  outline?: B;
  size?: SGBSSize;
  disabled?: B;
  emitMode?: EmitOnChangeMode;
  onChange?: (output: Output) => void;
  onReady?: () => void;
}

export interface IdName<T> {
  id: T;
  name: string;
}

export type IdNames<T> = Array<IdName<T>>;

export enum ProgressStep {
  PersonalInformation,
  AdditionalInformation,
  Confidentiality,
  Done,
}

export enum ModalType {
  PersonalEmailJustification,
  ExistingEmailError,
  IncompatibleEmailDomain,
  PositionChange,
  EditContactPositionChange,
}

export interface ContactCreatorFormModel {
  person?: Person;
  firstName?: string;
  lastName?: string;
  groupName?: string;
  employeeOfAccountId?: string;
  accountSuggestionIds?: string[];
  employeeOfAccountIso3?: string;
  civility: string;
  title: string;
  mainEmail: Email;
  emails: Email[];
  jobTitle: string;
  jobType: string;
  pepSpoType: PepSpoType;
  addresses: Address[];
  phones: Phone[];
  visibility: VisibilityType;
  owners?: OwnersCdbDto;
}

export interface ContactCreatorFormModelDto {
  givenName: string;
  name: string;
  type: string;
  employeeOfAccountId: string;
  civility: string | null;
  title?: string;
  jobTitle: string;
  jobType: string;
  visibility: VisibilityType;
  owners?: OwnersCdbDto;
  isPepOrSpo: boolean | undefined;
  addresses: AddressDto[];
  emails: Array<Email>;
  phones: Array<{ type: PhoneType; value: string }>;
  personId?: string;
}

interface AddressDto {
  street1: string;
  city: string;
  country: { name?: string; iso3: string };
  zipCode: string;
}

export type VisibilityType = "Public" | "Confidential";
export type PepSpoType = "N/A" | "Yes" | "No";

export interface Phone {
  type: string;
  iso2: string;
  areaCode?: string;
  number: string;
}

export interface Email {
  value: string;
  personalEmailJustificationId?: number;
}

export interface Address {
  id?: string;
  street1: string;
  zipCode: string;
  city: string;
  country: Country;
}

export interface Country {
  iso2?: string;
  iso3: string;
  name: string;
  areaCode?: string;
}
