import React from "react";
import { Radio } from "@sgbs-ui/core";
import { ContactPosition } from "../../../../../api/contacts/contacts.typings";

interface UpdateCurrentPositionProps {
  contact: ContactPosition;
  onSelect?: () => void;
  active?: boolean;
}

const UpdateCurrentPosition: React.FC<UpdateCurrentPositionProps> = ({ onSelect, active, contact }: UpdateCurrentPositionProps) => {
  const formatedCompanyName = !!contact.account?.name
    ? `${contact.account?.name} (${contact.account?.clientIds?.internalId?.level}) ${contact.account?.country?.iso3}`
    : `${contact.employeeOf?.name} (${contact.employeeOf?.bdrLegal?.level})`;

  const handleOnSelectCard = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    onSelect?.();
  };

  return (
    <div className={`card card-bordered p-3 my-3 ${active ? "active" : ""}`} onClick={handleOnSelectCard}>
      <div className="d-flex flex-row flex-grow-1">
        {onSelect && (
          <div className="col-1">
            <Radio id="update-current-position" color="primary" checked={active} onClick={() => onSelect?.()} />
          </div>
        )}

        <div className="flex-grow-1">
          <div className="font-weight-semibold">
            Add and close the current position as {contact?.jobType ?? "-"} at {formatedCompanyName}
          </div>
          <div>This action will close the current position and replace it with the new one</div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCurrentPosition;
