import { Field, useFormikContext } from "formik";
import * as React from "react";
import { ContactCreatorFormModel } from "../../common/typings";
import EmptySelectOption from "../../common/components/Form/EmptySelectOption";
import { map } from "lodash-es";
import { useRequestJobTypesReferentialQuery } from "../../api/referentials/useRequestReferential";
import { FetchFn, useSgConnectFetch } from "@sg-widgets/react-core";
import { ContactCreatorScope } from "../../common/sgConnectScopes";
import { Loader } from "@sgbs-ui/core";

const JobTypeField: React.FC = () => {
  const { touched, errors } = useFormikContext<ContactCreatorFormModel>();
  const fetch = useSgConnectFetch(ContactCreatorScope).fetch as FetchFn;
  const [isLoading, _, jobTypesReferentials] = useRequestJobTypesReferentialQuery(fetch);

  return (
    <>
      <Loader isVisible={isLoading} />
      <Field as="select" name="jobType" className={`form-control ${touched.jobType && errors.jobType && "is-invalid"}`}>
        <EmptySelectOption />
        {map(jobTypesReferentials, ({ name, id }) => (
          <option key={name} value={id}>
            {name}
          </option>
        ))}
      </Field>
    </>
  );
};

export default JobTypeField;
