import React from "react";
import { Radio } from "@sgbs-ui/core";

interface SimilarContactProps {
  onSelect?: () => void;
  active?: boolean;
}

const AddNewPositionCard: React.FC<SimilarContactProps> = ({ onSelect, active }: SimilarContactProps) => {

  const handleOnSelectCard = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    onSelect?.();
  };

  return (
    <div
      className={`card card-bordered p-3 my-3 ${active ? "active" : ""}`}
      onClick={handleOnSelectCard}
    >
      <div className="d-flex flex-row flex-grow-1">
        {onSelect && (
          <div className="col-1">
            <Radio id="add-new-position" color="primary" checked={active} onClick={() => onSelect?.()} />
          </div>
        )}

        <div className="flex-grow-1">
            <div className="font-weight-semibold"> Add as an additional position </div>
            <div>This action will add the new position and keep the current one active</div>
        </div>
      </div>
    </div>
  );
};

export default AddNewPositionCard;
