import React from "react";
import { ContactCreatorFormModel, ModalType, Email as CommonEmail } from "../../../../common/typings";
import {
  ContactCreatorContext,
  ContactCreatorContextProviderProps,
} from "../../../contextProvider/ContactCreatorContextProvider";
import { reduce, map } from "lodash-es";
import { useWidgetConfiguration } from "@sg-widgets/react-core";
import { WidgetEnv, logMessage } from "../../../../common/logger/logger";

export const useEditContactPositionActions = (
  contact: ContactCreatorFormModel,
  positionsIds: string[] | undefined,
  contactId: string | undefined,
  personId: string | undefined,
): [boolean, ModalType | null, () => void, () => void, () => void, (name: string, description: string) => void] => {
  const { actions, context } = React.useContext(ContactCreatorContext) as ContactCreatorContextProviderProps;
  const { isLoading, endPositionLoading, etag, forceSaveWithEtag, modalType } = context;

  const widgetConfiguration = useWidgetConfiguration();
  const env = widgetConfiguration?.environment as WidgetEnv;

  const sendLog = (name: string, description: string) => {
    logMessage({
      name: `modal_edit_contact_positions_${name}`,
      feature: "ic web-component ic-contact-editor",
      description,
      event: `modal_edit_contact_positions_${name}`,
      env,
    });
  };

  const createContact = (id?: string): void => {
    const mainEmail = getInitialIdentification(contact.mainEmail);
    const emails = map(contact.emails, getInitialIdentification);
    const editedContact = { ...contact, mainEmail, emails };
    actions.createContact(editedContact, forceSaveWithEtag ? etag : undefined, id);
  };

  const existingEmails = React.useMemo(() => {
    return reduce(contact?.emails, (prev, acc) => ({ ...prev, [acc.value]: acc }), {});
  }, [contact]);

  const getInitialIdentification = (email: CommonEmail): CommonEmail => {
    if (existingEmails[email.value]) {
      return {
        ...email,
        personalEmailJustificationId:
          existingEmails[email.value].personalEmailJustificationId ?? email.personalEmailJustificationId,
      };
    }
    return { ...email };
  };

  const editContact = (): void => {
    if (contactId) {
      const mainEmail = getInitialIdentification(contact.mainEmail);
      const emails = map(contact.emails, getInitialIdentification);
      const editedContact = { ...contact, mainEmail, emails };
      actions.editContact(contactId, editedContact, etag);
    }
  };

  const endPositions = (ids: string[]): void => {
    actions.endPositions(ids);
  };

  const closeModal = (): void => {
    actions.setModalType(null);
  };

  const confirmUpdate = (): void => {
    createContact(personId);
    if (positionsIds) {
      endPositions(positionsIds);
    }
    sendLog(
      "confirm_update_position",
      positionsIds ? "click confirm update position and close old" : "click confirm update position as new"
    );
    closeModal();
  };

  const handleEditContact = (): void => {
    sendLog("continue_editing", "click on No, continue to edit");
    editContact();
    closeModal();
  };

  return [isLoading || endPositionLoading, modalType, handleEditContact, confirmUpdate, closeModal, sendLog];
};
