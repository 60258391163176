import { Field, FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { find, isEmpty, map, size } from "lodash-es";
import * as React from "react";
import { Address, ContactCreatorFormModel, Country } from "../../common/typings";
import { MaxElementOfOptionalInput } from "./contants";
import { InvalidFeedback } from "../../common/components/InvalidFeedback/InvalidFeedback";
import { generateUID } from "@sgbs-ui/core";
import {
  ContactCreatorContext,
  ContactCreatorContextProviderProps,
} from "../contextProvider/ContactCreatorContextProvider";
import { useIntl } from "react-intl";

type Props = {
  countries: Country[];
};

const Options: React.VFC<Props> = ({ countries = [] }) => {
  return (
    <>
      {map(countries, ({ iso3, name }) => (
        <option key={iso3} value={iso3} label={name} />
      ))}
    </>
  );
};

const CountryOptions = React.memo(Options);

export const getDefaultAddress = (countries: Country[], employeeOfAccountIso3?: string): Address => {
  const baseAddress = {
    id: generateUID(),
    street1: "",
    zipCode: "",
    city: "",
  };

  if (isEmpty(employeeOfAccountIso3)) {
    return {
      ...baseAddress,
      country: { iso3: "FRA", iso2: "FR", name: "FRANCE" },
    };
  }
  const { iso3, name, iso2 } = find(countries, c => c.iso3 === employeeOfAccountIso3)!;
  return {
    ...baseAddress,
    country: { iso3, iso2, name },
  };
};

const AddAddressesForm: React.VFC = () => {
  const { touched, errors, values, setFieldValue } = useFormikContext<ContactCreatorFormModel>();
  const { context } = React.useContext(ContactCreatorContext) as ContactCreatorContextProviderProps;
  const { countries } = context;
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    const addresses = values.addresses ? [...values.addresses] : [];
    if (context.mainAddress) {
      addresses[0] = context.mainAddress;
      setFieldValue("addresses", addresses);
    }
  }, [context.mainAddress]);

  return (
    <div className="form-group">
      <div className="text-secondary font-weight-bold mb-1">
        {" "}
        {formatMessage({
          id: "contact.address",
          defaultMessage: "Address",
        })}{" "}
        (
        {formatMessage({
          id: "global.optional",
          defaultMessage: "optional",
        })}
        )
      </div>
      <FieldArray
        name="addresses"
        render={({ push, remove }: FieldArrayRenderProps) => (
          <>
            {map(values.addresses, ({ id }, index) => {
              const touchedAddress = touched.addresses?.[index];
              const errorAddress = errors.addresses?.[index] as any;
              return (
                <div key={index}>
                  <div className="row mx-0 mb-2">
                    <div className="col mr-2 px-0">
                      <Field
                        name={`addresses[${index}].street1`}
                        className={`form-control ${touchedAddress?.street1 && errorAddress?.street1 && "is-invalid"}`}
                        placeholder={formatMessage({
                          id: "contact.address.street",
                          defaultMessage: "Street",
                        })}
                      />
                      <InvalidFeedback errorMessage={touchedAddress?.street1 && errorAddress?.street1} />
                    </div>
                    <i
                      className="icon icon-md text-secondary col-auto px-0"
                      style={{ cursor: "pointer" }}
                      onClick={() => remove(index)}
                    >
                      delete
                    </i>
                  </div>
                  <div className="row mx-0 mb-2">
                    <div className="col-3 mr-2 px-0">
                      <Field
                        name={`addresses[${index}].zipCode`}
                        className={`form-control ${touchedAddress?.zipCode && errorAddress?.zipCode && "is-invalid"}`}
                        placeholder={formatMessage({
                          id: "contact.address.postalCode",
                          defaultMessage: "Postal code",
                        })}
                      />
                      <InvalidFeedback errorMessage={touchedAddress?.zipCode && errorAddress?.zipCode} />
                    </div>
                    <div className="col-3 mr-2 px-0">
                      <Field
                        name={`addresses[${index}].city`}
                        className={`form-control ${touchedAddress?.city && errorAddress?.city && "is-invalid"}`}
                        placeholder={formatMessage({
                          id: "contact.address.city",
                          defaultMessage: "City",
                        })}
                      />
                      <InvalidFeedback errorMessage={touchedAddress?.city && errorAddress?.city} />
                    </div>
                    <div className="col-5 px-0">
                      <Field as="select" name={`addresses[${index}].country.iso3`} className="form-control">
                        <CountryOptions countries={countries} />
                      </Field>
                      <InvalidFeedback errorMessage={errorAddress?.country.iso3} />
                    </div>
                  </div>
                </div>
              );
            })}
            {size(values.addresses) < MaxElementOfOptionalInput.ADDRESS && (
              <button
                type="button"
                className="btn btn-lg sgbs-btn-default btn-icon-text w-100 mb-3"
                onClick={() => push(getDefaultAddress(countries, values.employeeOfAccountIso3))}
              >
                <i className="icon">add</i>
                <span>
                  {formatMessage({
                    id: "contact.newAddress",
                    defaultMessage: "New address",
                  })}
                </span>
              </button>
            )}
          </>
        )}
      />
    </div>
  );
};

export default AddAddressesForm;
