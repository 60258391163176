import { last } from "lodash-es";
import * as React from "react";
import CommonEmailErrorModal from "../../common/components/ExistingEmailErrorModal/ExistingEmailErrorModal";
import { ModalType } from "../../common/typings";
import {
  ContactCreatorContext,
  ContactCreatorContextProviderProps,
} from "../contextProvider/ContactCreatorContextProvider";

const ExistingEmailErrorModal: React.VFC = () => {
  const { actions, context } = React.useContext(ContactCreatorContext) as ContactCreatorContextProviderProps;
  const { modalType, emailCheckError, saveError } = context;

  const error = emailCheckError ?? saveError;

  const contactName = last(error?.contacts)?.fullName;
  const companyName = last(error?.contacts)?.employeeOf?.name;
  const jobTitle = last(error?.contacts)?.jobTitle;

  const handleOnClose = (): void => {
    actions.setModalType(null);
  };

  return (
    <CommonEmailErrorModal
      show={modalType === ModalType.ExistingEmailError}
      contactLabel={`${contactName} - ${companyName} - ${jobTitle}`}
      onClose={handleOnClose}
    />
  );
};

export default ExistingEmailErrorModal;
