import React from "react";
import { ContactPosition } from "../../../../api/contacts/contacts.typings";
import SimilarContact from "../../../../common/components/SimilarContact/SimilarContact";
import UpdateCurrentPosition from "../../PositionChange/AddOrUpdatePosition/cards/UpdateCurrentPosition";
import AddNewPositionCard from "../../PositionChange/AddOrUpdatePosition/cards/AddNewPosition";

interface UpdateContatPositionProps {
  contact: ContactPosition;
  onSelectionChange: (contactIds: string[]) => void;
}

const UpdateContactPosition: React.FC<UpdateContatPositionProps> = ({
  contact,
  onSelectionChange,
}: UpdateContatPositionProps) => {
  const [isNewPositionActive, chooseNewPosition] = React.useState<boolean>(false);
  const [isUpdatePositionActive, chooseUpdatePosition] = React.useState<boolean>(true);

  const [selectedContactIds, setSelectedContacts] = React.useState<string[]>([contact.id]);

  React.useEffect(() => {
    if (selectedContactIds) {
      onSelectionChange(selectedContactIds);
    }
  }, [selectedContactIds]);

  const handleOnSelectUpdate = () => {
    chooseNewPosition(false);
    chooseUpdatePosition(true);
    setSelectedContacts([contact.id]);
  };

  const handleOnSelectNew = () => {
    chooseNewPosition(true);
    chooseUpdatePosition(false);
    setSelectedContacts([]);
  };

  return (
    <div>
      <SimilarContact contact={contact} />
      <h5 className="spacing-mt-1">Which action do you want to do?</h5>
      <UpdateCurrentPosition contact={contact} onSelect={handleOnSelectUpdate} active={isUpdatePositionActive} />
      <AddNewPositionCard onSelect={handleOnSelectNew} active={isNewPositionActive} />
    </div>
  );
};

export default UpdateContactPosition;
