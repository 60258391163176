import { Field, FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { map, size, trim } from "lodash-es";
import * as React from "react";
import { ContactCreatorFormModel, Email } from "../../common/typings";
import { MaxElementOfOptionalInput } from "./contants";
import { InvalidFeedback } from "../../common/components/InvalidFeedback/InvalidFeedback";
import { useIntl } from "react-intl";

const EmptyEmail: Email = {
  value: "",
};

const AddEmailsForm: React.VFC = () => {
  const { touched, errors, values, setFieldValue } = useFormikContext<ContactCreatorFormModel>();
  const { emails } = values;
  const { formatMessage } = useIntl();

  return (
    <div className="form-group">
      <div className="text-secondary font-weight-bold mb-1">
        {formatMessage({
          id: "contact.emailAddress",
          defaultMessage: "Email address",
        })}{" "}
        (
        {formatMessage({
          id: "global.optional",
          defaultMessage: "optional",
        })}
        )
      </div>
      <FieldArray
        name="emails"
        render={({ push, remove }: FieldArrayRenderProps) => (
          <>
            {map(emails, (_, index) => {
              const touchedEmail = touched.emails?.[index];
              const errorEmail = errors.emails?.[index] as any;
              return (
                <div key={index} className="row mx-0 mb-2">
                  <div className="col px-0 mr-2">
                    <Field
                      name={`emails[${index}].value`}
                      className={`form-control ${touchedEmail?.value && errorEmail?.value && "is-invalid"}`}
                      placeholder="Email"
                      onBlur={event => {
                        setFieldValue(`emails[${index}].value`, trim(event?.currentTarget?.value));
                        setFieldValue(`emails[${index}].personalEmailJustificationId`, undefined);
                      }}
                    />
                    <InvalidFeedback errorMessage={touchedEmail?.value && errorEmail?.value} />
                  </div>
                  <i
                    className="icon icon-md text-secondary col-auto px-0"
                    style={{ cursor: "pointer" }}
                    onClick={() => remove(index)}
                  >
                    delete
                  </i>
                </div>
              );
            })}
            {size(emails) < MaxElementOfOptionalInput.EMAIL && (
              <button
                type="button"
                className="btn btn-lg sgbs-btn-default btn-icon-text w-100 mb-3"
                onClick={() => push(EmptyEmail)}
              >
                <i className="icon">add</i>
                <span>{formatMessage({
          id: "contact.newEmailAddress",
          defaultMessage: "New email address",
        })}</span>
              </button>
            )}
          </>
        )}
      />
    </div>
  );
};

export default AddEmailsForm;
