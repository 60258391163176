import * as React from "react";
import { noop } from "lodash-es";
import { SGBSColor, SGBSSize } from "@sgbs-ui/core";

export interface Props {
  show?: boolean;
  withHeader?: boolean;
  title?: string;
  titleClass?: string;
  size?: SGBSSize;
  subTitle?: string;
  withAction?: boolean;
  borderColor?: SGBSColor;
  onClose?: () => void;
  renderActions?: () => JSX.Element;
}

const ReactModal: React.FC<Props> = ({
  show = false,
  withHeader = true,
  withAction = true,
  size = "lg",
  titleClass = "",
  title,
  subTitle,
  borderColor,
  children,
  renderActions = noop,
  onClose = noop,
}) => {
  let modalContent;
  const onShouldClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (modalContent && !modalContent.contains(event.target)) {
      onClose();
    }
  };
  const borderColorClass = borderColor ? `border border-${borderColor}` : "";
  return show ? (
    <div
      className="modal fade show modal-dialog-centered"
      tabIndex={-1}
      role="dialog"
      style={{ zIndex: 2000 }}
      onClick={onShouldClose}
      aria-hidden="true"
    >
      <div className={`modal-dialog modal-${size}`} role="document">
        <div className={`modal-content ${borderColorClass}`} ref={ref => (modalContent = ref)}>
          {withHeader && (
            <div className="modal-header">
              <span>
                <h3 className={`modal-title ${titleClass}`}>{title}</h3>
                {subTitle && <h5 className="modal-title">{subTitle}</h5>}
              </span>
              <button type="button" className="close icon" data-dismiss="modal" onClick={onClose}>
                <i className="icon">close</i>
              </button>
            </div>
          )}
          {!withHeader && <span className="pt-5" />}
          <div className="modal-body modal-overflow-initial">{children}</div>
          {withAction && renderActions()}
        </div>
      </div>
    </div>
  ) : null;
};

export default ReactModal;
