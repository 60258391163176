import React from "react";
import { defaultLuxonDateFormat, toDisplayedDate } from "@ic-anywhere/ic-utils";
import { ContactPosition } from "../../../api/contacts/contacts.typings";
import { Radio } from "@sgbs-ui/core";

type SimilarContactProps = {
  contact: ContactPosition;
  onSelect?: (id: string) => void;
  active?: boolean;
};

const SimilarContact: React.FC<SimilarContactProps> = ({ contact, onSelect, active }) => {
  const formatedCompanyName = !!contact.account?.name
  ? `${contact.account?.name} (${contact.account?.clientIds?.internalId?.level}) ${contact.account?.country?.iso3}`
  : `${contact.employeeOf?.name} (${contact.employeeOf?.bdrLegal?.level})`;

  const handleOnSelectCard = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    onSelect?.(contact.id);
  };

  return (
    <div
      key={contact.id}
      className={`card card-bordered p-3 my-3 ${active ? "active" : ""}`}
      onClick={handleOnSelectCard}
    >
      <div className="d-flex flex-row flex-grow-1">
        {onSelect && (
          <div className="col-1">
            <Radio id={`similar-contact-${contact.id}`} color="primary" checked={active} onClick={() => onSelect?.(contact.id)} />
          </div>
        )}

        <div className="flex-grow-1">
          <div className="text-secondary">{formatedCompanyName}</div>
          <div className="mb-1 mt-1 font-weight-semibold">{contact.jobType}</div>
          <div>{contact.emails?.find(email => email.type === "Professional")?.value}</div>
          <div>{contact.phones?.find(phone => phone.type === "Business" || phone.type === "Mobile")?.value}</div>
          {onSelect && (
            <div className="d-flex flex-row mt-3">
              {contact.isActive && (
                <div className="text-secondary text-small mr-3">
                  From {toDisplayedDate(contact.auditInformation?.createdOn, defaultLuxonDateFormat)}
                </div>
              )}
              {contact.isActive ? (
                <span className="badge badge-pill badge-discreet-info text-small">Current position</span>
              ) : (
                <span className="badge badge-pill badge-discreet-secondary text-small">Past position</span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SimilarContact;
